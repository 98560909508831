<template>
  <div>
    <p
      v-for="(item, index) in ambitionList"
      :key="index"
      class="item"
    >
      <span class="detail-wrapper">
        <span class="image-wrapper"><img
          class="image"
          :src="item.imgSrc"
        ></span>
        <span class="title">{{ item.title }}</span>
        <span class="detail">{{ item.detail }}</span>
      </span>
      <span
        v-if="index !== ambitionList.length - 1"
        class="line"
      />
    </p>
  </div>
</template>

<script>
  export default {
    name: 'AmbitionList',
    props: {
      ambitionList: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>

<style lang="stylus" scoped>
  div
    display flex
    flex-direction row
    justify-content center
    .item
      display flex
      flex-direction row
      justify-content center
      align-items center
      height 124px
      .detail-wrapper
        margin 0 50px
        height 124px
        display flex
        flex-direction column
        justify-content space-between
        align-items center
        .image-wrapper
          .image
            width 36px
            height auto
            margin auto
        .title
          font-size 20px
          line-height 24px
          color #333
          font-weight bold
        .detail
          font-size 16px
          line-height 16px
          color #666
      .line
        width 1px
        height 100px
        background-color #CCC
</style>
