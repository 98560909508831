<template>
  <slot ref="slot1" />
  <teleport
    v-if="visible"
    to="body"
  >
    <div
      ref="tooltipEl"
      class="tooltip"
      :style="style"
    >
      <svg
        ref="arrowEl"
        :style="arrowStyle"
        class="tooltip-arrow"
        width="14"
        height="5"
        viewBox="0 0 14 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.9"
          d="M2.15222 4.12752L5.92389 0.43624C6.51821 -0.145412 7.48179 -0.145413 8.07611 0.43624L11.8478 4.12752C12.4186 4.68616 13.1928 5 14 5L-5.16561e-07 5C0.80724 5 1.58142 4.68616 2.15222 4.12752Z"
          fill="black"
        />
      </svg>
      <span>{{ props.content }}</span>
    </div>
  </teleport>
</template>

<script setup lang="ts">
  import {
    ref, nextTick, reactive, CSSProperties, onBeforeUnmount, watchEffect,
  } from 'vue'

  import { computePosition, arrow, offset } from '@floating-ui/dom'

  const tooltipEl = ref()
  const arrowEl = ref()

  const props = defineProps<{
    target: HTMLElement
    visible: boolean
    content: string
  }>()

  const emit = defineEmits(['update:visible'])

  const style = reactive<CSSProperties>({})
  const arrowStyle = reactive<CSSProperties>({})

  const handleClick = (e: Event) => {
    if (!e.composedPath().includes(tooltipEl.value)) {
      emit('update:visible', false)
    }
  }

  const listenClick = () => {
    document.addEventListener('click', handleClick)
  }

  const removeListen = () => {
    document.removeEventListener('click', handleClick)
  }

  const updateStyle = async () => {
    const { x, y, middlewareData } = await computePosition(
      props.target,
      tooltipEl.value,
      {
        placement: 'bottom',
        middleware: [
          offset(6),
          arrow({ element: arrowEl.value }),
        ],
      },
    )
    style.transform = `translate(${x}px, ${y}px)`
    arrowStyle.transform = `translate(${middlewareData?.arrow?.x}px, -4px)`
  }

  watchEffect(async () => {
    if (props.visible) {
      removeListen()
      await nextTick()
      await updateStyle()
      setTimeout(listenClick)
    } else {
      removeListen()
    }
  })

  onBeforeUnmount(removeListen)

</script>

<style lang="stylus" scoped>
.tooltip, .tooltip-arrow
  position absolute
  left 0
  top 0

.tooltip
  padding 10px 15px
  flex-center()
  background #000000
  border-radius 18px
  z-index 100
  color #fff
  font-size 14px
  line-height 16px
</style>
