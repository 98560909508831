<template>
  <div class="container">
    <div class="inner">
      <div class="left-nav">
        <h2 class="title">侵权投诉指引</h2>
        <a
          v-for="(item, index) in list"
          :key="index"
          :href="'#part' + index"
          class="list"
        >{{
          item
        }}</a>
      </div>
      <div class="right-content">
        <p>
          <br>
          2020.10.21
        </p>
        <strong
          id="part0"
          class="title"
        >一. 小红书声明</strong>
        <p>
          &ldquo;小红书&rdquo;为中立的平台服务提供方，仅为用户提供信息存储空间，&ldquo;小红书&rdquo;不对用户及商家在平台上发表、转载的内容提供任何形式的保证。根据《民法典》、《电子商务法》、《信息网络传播权保护条例》及其他相关法律法规的规定，&ldquo;小红书&rdquo;特制定本侵权投诉指引，任何第三方（包括但不限于个人、公司、企业、社会团体等）认为平台上的笔记/商品/服务存在侵犯其合法权益的内容，均可按照本指引向&ldquo;小红书&rdquo;发起书面投诉。
        </p>
        <p>
          如果发现&ldquo;小红书&rdquo;平台上的笔记/商品/服务存在侵犯他人合法权益，而自身并非相关权利人或不符合相关侵权投诉资质时，您可以通过&ldquo;小红书&rdquo;平台设置的举报功能进行相关反馈，&ldquo;小红书&rdquo;将独立进行调查处理并及时答复结果。
        </p>
        <p>
          <strong>侵权投诉是一项严肃的法律行为，根据国家相关法律规定，在&ldquo;小红书&rdquo;平台进行侵权投诉、反通知及相关申诉时，应提供己方的真实身份信息，&ldquo;小红书&rdquo;平台不接受任何形式的匿名投诉。</strong>
        </p>
        <strong
          id="part1"
          class="title"
        >二. 侵权投诉受理案由</strong>
        <p>&ldquo;小红书&rdquo;为中立的平台服务提供方，受理下列三大类侵权投诉，共计九种案由：</p>
        <ol>
          <li>侵犯自然人人身权益的情形，包括：名誉中伤、肖像权及隐私泄露、假冒个人身份；</li>
          <li>
            侵犯知识产权的情形，包括：商标（<strong>含假冒伪劣</strong>）、专利、著作权（<strong>含定向搬运内容的假账号投诉</strong>）、商业秘密；
          </li>
          <li>
            其他侵犯企业合法权益的情形，包括：<strong>商誉诋毁、</strong>假冒企业身份（非注册商标）。
          </li>
        </ol>
        <p>对于不属于侵权投诉范畴内的违法违规内容，建议通过小红书App举报功能进行反馈。</p>
        <strong
          id="part2"
          class="title"
        >三. 侵权投诉渠道</strong>
        <p>
          为高效解决侵权问题，&ldquo;小红书&rdquo;建议权利人可以直接与对方协商解决，当您无法联系或协商无效时，可以通过如下方法向&ldquo;小红书&rdquo;进行侵权投诉。
        </p>
        <ol>
          <li>APP端线上投诉 ：作为权利人，如果您在使用小红书APP的过程中发现某篇笔记或商品存在侵犯您合法权益的情况，您可以进入该篇笔记或商品的浏览页面，通过APP设置在举报功能界面中的&ldquo;侵权投诉&rdquo;功能进行线上投诉。具体操作指南请参见：<a href="http://xhslink.com/gGssBe">http://xhslink.com/gGssBe</a></li>
          <li>邮箱投诉：当您并非小红书APP的用户或因为其他原因不便使用线上投诉时，也可以选择根据本侵权投诉指引的要求，将相关资质、侵权投诉通知书及初步证明材料发送至：<a href="mailto:qinquan@xiaohongshu.com">qinquan@xiaohongshu.com</a> 邮箱。</li>
        </ol>
        <ul>
          <li>注意：为加快和节省侵权投诉的资质审核环节，&ldquo;小红书&rdquo;更推荐您使用APP端线上投诉哦。</li>
        </ul>
        <strong
          id="part3"
          class="title"
        >四. 侵权通知程序</strong>
        <p>
          如权利人认为&ldquo;小红书&rdquo;平台上的用户笔记、商城商品或服务等内容侵犯了其合法个人权益、企业权益的，请按照以下流程及要求向&ldquo;小红书&rdquo;发起书面投诉。
        </p>
        <ol>
          <li>
            权利人下载
            <a
              href="https://fe-video-qc.xhscdn.com/fe-platform/1b1106653bb7e5861a4a4a155d259a81cbc304d8.docx?attname=fe-platform/1b1106653bb7e5861a4a4a155d259a81cbc304d8.docx.docx"
            >侵权投诉通知书.docx</a>
            ，按照表中要求填写信息并准备初步证明材料（按照第六项标准）。
          </li>
          <li>
            将填写完的
            <a
              href="https://fe-video-qc.xhscdn.com/fe-platform/1b1106653bb7e5861a4a4a155d259a81cbc304d8.docx?attname=fe-platform/1b1106653bb7e5861a4a4a155d259a81cbc304d8.docx.docx"
            >侵权投诉通知书.docx </a>及初步证明材料作为附件，以&ldquo;权利人姓名+投诉案由&rdquo;为邮件名，发送到第三项标准中指定的邮箱（文件应为打印后盖章/签字的扫描版本；要求冻结、屏蔽、删除的侵权链接的投诉，应提供word或excel附件，并列明准确名称、网页截图及在&ldquo;小红书&rdquo;上的链接地址）。主要的事实、理由、被投诉目标以及相应请求，请在邮件正文中予以撰写清楚。
          </li>
          <li>
            &ldquo;小红书&rdquo;收到材料后会进行形式审核。对于符合要求的投诉予以处理，对于案由不明、材料不符或其他不符合要求的情况， &ldquo;小红书&rdquo;不予受理，直至权利人补充完整资料。 &ldquo;小红书&rdquo;正式受理投诉之后，会对材料内容进行表面审核并作出审核结果。如投诉审核通过， &ldquo;小红书&rdquo;会采取相应措施防止损害进一步扩大。
          </li>
          <li>
            &ldquo;小红书&rdquo;在审查过程中有权视具体情况要求权利人提供或补充相应材料，权利人应在&ldquo;小红书&rdquo;设定的合理期限内予以答复或补充，期满未作答复或补充的，视为权利人撤回投诉。权利人也可主动在转发【原投诉邮件】基础上要求撤回投诉。无论是权利人主动撤回还是被视为撤回，&ldquo;小红书&rdquo;有理由认为原被投诉的侵权情况自始不存在。若在&ldquo;小红书&rdquo;对被投诉内容进行了处理之后权利人要求撤回投诉的，&ldquo;小红书&rdquo;可以准许，但就相同事实理由不再接受权利人的新投诉。
          </li>
          <li>
            <strong>根据国家相关法律规定，&ldquo;小红书&rdquo;平台在收到投诉人的相关投诉材料后，可能将其转交被投诉用户或商家，以便双方积极举证。转交材料的范围将包括投诉人姓名、联系邮箱、权属证明及其他初步侵权证据，但不包括投诉人的私人电话、身份证件等隐私信息和投诉人提前声明不便转交的材料。</strong>
          </li>
        </ol>
        <strong
          id="part4"
          class="title"
        >五. 反通知程序</strong>
        <p>
          投诉人应知晓，当&ldquo;小红书&rdquo;根据投诉人的申请及证明材料，自主决定采取冻结、屏蔽、删除等合理处置措施，随后会将通知及相关措施告知&ldquo;小红书&rdquo;的被投诉用户或商家。
        </p>
        <p>
          如&ldquo;小红书&rdquo;被投诉用户或商家认为其行为并未侵犯他人的合法权益，请在接到通知后的5个工作日内，按照以下流程及要求向&ldquo;小红书&rdquo;发起申诉。
        </p>
        <ol>
          <li>
            被投诉用户或商家下载<a
              href="https://fe-video-qc.xhscdn.com/fe-platform/939cf5f76d48c9d88a6cf33b38c94fb7fb790bc2.docx?attname=fe-platform/939cf5f76d48c9d88a6cf33b38c94fb7fb790bc2.docx.docx"
            >未侵权声明.docx</a>，按照表中要求填写并准备申诉材料（按照第七项标准）。
          </li>
          <li>
            将填写完的<a
              href="https://fe-video-qc.xhscdn.com/fe-platform/939cf5f76d48c9d88a6cf33b38c94fb7fb790bc2.docx?attname=fe-platform/939cf5f76d48c9d88a6cf33b38c94fb7fb790bc2.docx.docx"
            >未侵权声明.docx</a>及申诉材料作为附件，以&ldquo;申诉人姓名+申诉案由&rdquo;
            为邮件名，发送到第三项标准中指定的邮箱。
          </li>
          <li>
            &ldquo;小红书&rdquo;接到声明后会及时进行审核，同时将该声明转送至投诉人。若审核后认为被投诉用户或商家申诉理由充分、证据材料充足，&ldquo;小红书&rdquo;将视情况决定是否解除前述合理处置措施，并告知投诉人可以向有关主管部门投诉或者向人民法院起诉。在声明转达至投诉人后十五日内，&ldquo;小红书&rdquo;未收到权利人已经起诉或者起诉通知的，可解除前述合理处置措施。
          </li>
        </ol>
        <strong
          id="part5"
          class="title"
        >六. 初步证明材料</strong>
        <p>
          符合要求的侵权投诉通知，除上述提及的<a
            href="https://fe-video-qc.xhscdn.com/fe-platform/1b1106653bb7e5861a4a4a155d259a81cbc304d8.docx?attname=fe-platform/1b1106653bb7e5861a4a4a155d259a81cbc304d8.docx.docx"
          >侵权投诉通知书.docx</a>外，还应该提供如下四类证据：1. 投诉人身份证明文件；2. 委托证明文件（如有）；3.
          自身权属的证明文件；4. 侵权事实的证明文件。
        </p>
        <ol>
          <li>
            投诉人身份证明文件：
            <ul>
              <li>
                个人：包括有效期内的身份证、居民户口薄、护照、外国人居留证等。如提供居民身份证，请提供正反两面复印件；如提供其他证件，请提供证件首页和个人页复印件（或原件扫描件）。最常用的有效身份证件是有效期内的居民身份证。
              </li>
              <li>
                公司、企业或社会团体：企事业单位及社会团体，需要提供有效期内的营业执照、事业单位法人证书、社会团体法人登记证书等证件的复印件（或原件扫描件）并加盖公章。
              </li>
              <li>境外公司主体：境外企业续存证明（经当地使、领馆认证）并提供对应的中文译本。</li>
            </ul>
          </li>
          <p><strong>*注意：&ldquo;小红书&rdquo;在收到上述文件材料后仅做身份认定审核，除根据转通知程序告知纠纷双方的姓名、公司名称及联系邮箱外，并不会将其他身份信息材料向任何第三方透露。</strong></p>
          <li>
            委托证明文件:
            如【权利人】委托他人处理侵权事宜，除第1项证明文件以外，还需提供真实、合法的授权委托书（权利人签名或盖章）以及代理人的身份证明文件（按第1项标准准备）。境外公司主体委托境内代理人的，其授权委托书应经使馆认证并提供中文译本。
          </li>
          <li>
            自身权属的证明文件：
            <ul>
              <li>
                人身权利侵权：
                <ul>
                  <li>肖像权：需手持身份证照片，以便审核验证投诉人即肖像对应的本人；</li>
                  <li>假冒个人身份：需手持身份证照片及自己具有特殊身份的相关证明。</li>
                </ul>
              </li>
              <li>
                知识产权侵权：
                <ul>
                  <li>
                    著作权：著作权登记证书、创作手稿、作品发表时间证明、认证机构出具的权属证明以及取得著作权权利的合同等【其中图片的权属证明接受拍摄时场景图、psd格式文件、高清原图等】；著作权作品合法授权的样本【包括网站、书籍、实物照片等】，以便小红书进一步确认权属；
                  </li>
                  <p>
                    *
                    <strong>&ldquo;定向搬运内容的假账号&rdquo;是指在小红书平台开设专门模仿某个知名博主的社交账号，用于搬运该博主在其他平台发布的视频、图片、文字等内容。对该类侵权行为投诉，除对被搬运内容的版权证明，权利人还应提供证据证明自己掌握着被搬运内容官方发布渠道的事实，如官方账号的后台管理界面截图。</strong>
                  </p>
                  <li>商标权：商标注册证、商标许可合同；</li>
                  <li>专利权：专利证书、专利的权利要求与说明书等。</li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
        <ol start="4">
          <li>
            侵权事实的证明文件：
            <ul>
              <li>
                自然人人身权益：
                <ul>
                  <li>名誉权：书面陈述恶意中伤、侮辱及内容系捏造诽谤的事实和产生的损害结果，以及其他证明相反事实的证据材料；</li>
                  <li>肖像权：书面陈述被投诉人未经授权使用自己肖像的事实；</li>
                  <li>隐私泄露：书面说明被泄露的信息属于个人隐私的原因；</li>
                  <li>假冒他人身份：书面陈述被投诉账号与自己的身份相关的事实，并提供自己具有特殊身份的相关佐证（如有）；</li>
                </ul>
              </li>
              <li>
                知识产权侵权：
                <ul>
                  <li>著作权：被投诉内容抄袭复制的事实说明、被投诉内容未经授权的说明；</li>
                  <p>
                    <strong>* </strong><strong>注意：</strong>针对定向搬运内容的假冒账号提出投诉：应另行提供在其他平台更早发布被搬运内容的事实证明。
                  </p>
                  <li>商标权：被投诉内容擅自使用商标的事实说明、被投诉内容未经授权的说明；</li>
                  <li>专利权：被投诉内容擅自使用专利的事实说明（需进行比对说明）、被投诉内容未经授权的说明。</li>
                </ul>
              </li>
              <li>
                其他企业权益受损：
                <ul>
                  <li>
                    商誉诋毁侵权：说明被投诉内容存在的不实之处、对被投诉内容事实的正确陈述并对其充分举证、对被投诉内容存在恶意的说明、对自身损害结果的陈述及佐证；
                  </li>
                  <li>
                    假冒企业身份：说明被投诉账号与权利人无关且未经过任何授权、自身损害结果的陈述及佐证。
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
        <ul />
        <ol start="5">
          <li>
            其他形式要求：
            <ul>
              <li>
                投诉时要求冻结、屏蔽、删除侵权链接的，请在邮件正文以文本形式提供准确链接地址，或通过word或excel附件中列明。
              </li>
              <li>
                联系方式： 权利人或代理人在
                <a
                  href="https://fe-video-qc.xhscdn.com/fe-platform/1b1106653bb7e5861a4a4a155d259a81cbc304d8.docx?attname=fe-platform/1b1106653bb7e5861a4a4a155d259a81cbc304d8.docx.docx"
                >侵权投诉通知书.docx </a>中填写的电话、邮箱、地址应为有效的联系方式，且投诉人应保持通信畅通。如因权利人或代理人提供的联系方式不准确或有遗漏，无法及时收到反馈结果，由权利人或代理人自行承担不利后果。
              </li>
              <li>
                <strong>小红书暂不接受除中文以外语言的投诉。外文投诉文件请附上中文翻译，小红书将以其中文翻译内容为准。We
                  do not accept any complaint in languages other than Chinese. Please attach a
                  Chinese translation to the complaint document in foreign language, and the Chinese
                  translation will prevail.
                </strong>
              </li>
              <li>
                <strong>*鉴于&ldquo;小红书&rdquo;在收到投诉/申诉的证据材料，可能根据转通知程序转呈争议另一方，故请在提供文件时请自行添加水印或者遮盖敏感信息。*</strong>
              </li>
            </ul>
          </li>
        </ol>
        <strong
          id="part6"
          class="title"
        >七. 申诉材料</strong>
        <ol>
          <li>
            被冻结、屏蔽、删除的侵权链接的被投诉方的姓名（名称）、地址及营业执照（单位）、身份证明（个人）、联系方式等；
          </li>
          <li>
            要求恢复的被冻结、屏蔽的侵权链接的准确名称、网页截图及在小红书上的链接地址（于word或excel附件中列明）；
          </li>
          <li>
            认为不构成侵权的初步证明材料
            <ul>
              <li>
                权属证明材料：包括但不限于对被冻结、屏蔽的侵权链接享有知识产权或使用权、信息网络传播权等权属证明、创作手稿、经权威机构签发的作品创作时间戳、作品备案证书、授权书等能证明被投诉方拥有相关权利的有效权属证明；
              </li>
              <li>
                被投诉方提供的笔记/商品/服务未构成侵权的理由陈述：针对投诉理由的直接驳斥，包括对事实的否认、相关法律法规的说明等。
              </li>
              <li>
                被投诉方提供的笔记/商品/服务未构成侵权的证明材料：包括但不限于被投诉方提供的笔记/商品/服务等未侵犯权利人的人身权、著作权、商标权或专利权等权利的有效证明材料。
              </li>
            </ul>
          </li>
          <li>
            被投诉方的保证：被投诉方应对
            <a
              href="https://fe-video-qc.xhscdn.com/fe-platform/939cf5f76d48c9d88a6cf33b38c94fb7fb790bc2.docx?attname=fe-platform/939cf5f76d48c9d88a6cf33b38c94fb7fb790bc2.docx.docx"
            >未侵权声明.docx </a>中的陈述和所提供材料的真实性、有效性和合法性负责，保证承担因被投诉方的答辩而恢复被投诉笔记/商品/服务给小红书造成的全部损失及由此产生的全部法律责任。
          </li>
          <li>如委托他人进行申诉，还应提供合法有效的授权委托书及受托人的身份证明文件。</li>
          <li>
            被投诉方应提供经过亲笔签名或加盖公章的
            <a
              href="https://fe-video-qc.xhscdn.com/fe-platform/939cf5f76d48c9d88a6cf33b38c94fb7fb790bc2.docx?attname=fe-platform/939cf5f76d48c9d88a6cf33b38c94fb7fb790bc2.docx.docx"
            >未侵权声明.docx </a>扫描件，否则小红书将不予受理。
          </li>
        </ol>
        <strong
          id="part7"
          class="title"
        >八. 投诉注意事项</strong>
        <ol>
          <li>
            为保证投诉的问题能够及时有效的解决，请按照该指引的要求提供真实有效、完整清晰的材料，否则投诉不予处理。
          </li>
          <li>
            投诉人应保证所提交的材料都是真实、有效和合法的，并承担要求&ldquo;小红书&rdquo;冻结、屏蔽、删除相关侵权链接所引起的一切法律后果和连带责任。
          </li>
          <li>
            &ldquo;小红书&rdquo;有权利和义务在法律法规规定及审核人员的认知水平、能力范围内，对投诉人的投诉作出判断、处理和答复。&ldquo;小红书&rdquo;不对投诉人的投诉是否得到某种结果作出任何形式的承诺或保证。
          </li>
          <li>《侵权投诉指引》自公布即生效，在法律规定的范围内，&ldquo;小红书&rdquo;有权修改本指引。</li>
        </ol>
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ContentDispute',
    data: () => ({
      list: [
        '小红书声明',
        '侵权投诉受理案由',
        '侵权通知程序',
        '反通知程序',
        '初步证明材料',
        '申诉材料',
        '联系方式',
        '投诉注意事项',
      ],
    }),
  }
</script>
<style lang="stylus" scoped>
.container
  background #FBFBFB
  .inner
    width 960px
    margin 50px auto 0 auto
    overflow hidden
    position relative
    .left-nav
      width 230px
      float left
      background #FFFFFF
      position fixed
      top 156px
      .title
        font-size 16px
        color #000000
        font-weight 600
        margin 11px 10px
      .list
        color #555
        padding 11px 0 10px 30px
        margin 0
        display block
        border-left 2px solid #FFFFFF
        &:hover
          color #FF0036
          border-left 2px solid #FF0036
    .right-content
      width 710px
      float left
      margin-left 250px
      background #FFFFFF
      margin-bottom 50px
      padding 20px 20px 20px 20px
      .title
        font-size 20px
        font-weight 600
        margin 30px auto 0 auto
        text-align center
        color #000000
        border-bottom 1px dashed #EEE
        padding-bottom 30px
      .content
        padding 50px 0 0 0
        .short-nav
          font-size 14px
          font-weight 600
          color #000000
          margin 0
        .test
          margin 7px 0 0 0
          line-height 28px
          .num
            margin-right 10px
      h3
        font-size 14px
        font-weight 600
        color #000000
        margin 30px 0 0 0
      ol
      ul
        display block
        margin 15px auto
        padding-left 30px
      .no-indent
        padding-left 15px
      li
        margin 15px auto
      a
        color #5b92e1
      .mail
        margin 0 0 0 6px
        color #5b92e1
      .alpha-list
        list-style-type lower-alpha
</style>
