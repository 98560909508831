<template>
  <div>
    <div class="all-issue">
      <div class="issue-wrapper">
        <div class="issue-selector">
          <span
            v-for="(issue, index) in groupIssues"
            :key="index"
            class="selector"
            :class="{ selected: currentName === issue.groupName }"
            @click="changeCurrent(issue.groupName)"
          >{{ issue.groupName }}
            <span class="tooltip-text">{{ issue.groupName }}</span>
          </span>
        </div>
        <div
          v-for="(item, index) in normalIssue"
          :key="index"
          class="inner"
          :style="item.style"
        >
          <template v-if="item.html">
            <div v-html="item.html" />
          </template>
          <template v-if="!item.html">
            <h4 class="question">Q：{{ item.question }}</h4>
            <p
              class="content"
              v-html="`A：${ item.content }`"
            />
          </template>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BrandIssue',
    props: {
      groupIssues: {
        type: Array,
        default: () => ([]),
      },
    },
    data() {
      return {
        currentName: '开户流程',
      }
    },
    computed: {
      normalIssue() {
        return this.groupIssues.find(issue => issue.groupName === this.currentName)?.groupContent
      },
    },
    methods: {
      changeCurrent(groupName) {
        this.currentName = groupName
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .all-issue
    width 1002px
    margin 0 auto
    .issue-wrapper
      .issue-selector
        display flex
        flex-direction row
        justify-content center
        align-items center
        .selector
          width 200px
          height 40px
          border-radius 100px
          background-color #fff
          text-align center
          line-height 40px
          color #747474
          font-size 16px
          cursor pointer
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
          margin 0 7px
          padding 0 10px
          position relative
          .tooltip-text
            width 100%
            visibility hidden
            background-color #aeaeae
            color #fff
            text-align center
            border-radius 6px
            font-size 12px
            height 16px
            line-height 16px
            padding 1px 5px
            position absolute
            z-index 1
            bottom 110%
            left 0
          &.selected
            color #fff
            background-color #aeaeae
          &:hover
            color #fff
            background-color #aeaeae
            .tooltip-text
              visibility hidden
      .inner
        margin-top 40px
        .question
          font-size 20px
          line-height 1.25
          text-align left
          font-weight normal
          color #2c2c2c
          margin 0
        .content
          font-size 15px
          color #818181
          margin 16px 0 32px 0
</style>
