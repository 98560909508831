<template>
  <div class="news-list">
    <div class="list" v-for="list in newsList" :key="list.id">
      <div class="news-item"
           :class="{hoverable: item.src || item.index}"
           :style="compulsoryStyle"
           v-for="item in list.activities"
           @click="viewReport(item)"
           :key="item.id">
        <span class="title">{{item.title}}</span>
        <span class="date-press">
          <span class="press" v-if="item.press">{{item.press}}</span>
          <span class="date">{{item.date}}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CommonNewsList',
    props: {
      newsList: {
        type: Array,
        default: () => ([]),
      },
      compulsoryStyle: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
      }
    },
    methods: {
      viewReport(news) {
        if (news.src) {
          window.open(news.src, '_blank')
        }

        if (news.index) {
          const { href } = this.$router.resolve({
            name: 'ActivityItem',
            query: {
              id: news.index,
            },
          })

          window.open(href, '_self')
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .news-list
    width 940px
  .list
    display flex
    flex-direction column
    justify-content flex-start
    align-items flex-start
  .news-item
    width 940px
    font-size 14px
    line-height 16px
    color #555
    margin 0 0 30px 0
    display flex
    flex-direction row
    justify-content space-between
    align-items center
    &.hoverable
      cursor pointer
      &:hover
        color #FF2442
    .title
      font-size 14px
      line-height 16px
    .date-press
      display flex
      flex-direction row
      justify-content flex-end
      align-items center
      .press
        margin-right 50px
        text-align right
      .date
        width 75px
        font-family Monospaced
        text-align right

</style>
