<template>
  <span v-html="getTitle()" />
</template>

<script setup lang="ts">
  import redEmojiMap from '~/assets/emoji.json'

  const props = defineProps<{
    content: string
    supportWebp: boolean
  }>()

  const getTitle = () => props.content?.replace(/\[(.*?)]/g, (match: string) => {
    // @ts-ignore
    if (redEmojiMap[match]) {
      // @ts-ignore
      return `<img src="${redEmojiMap[match]}?imageView2/2/w/100/format/${props.supportWebp ? 'webp' : 'png'}">`
    }
    return match
  })
</script>

<style lang="stylus" scoped>
span &:deep(img)
  height 14px
  transform translate(0px, 1px)
</style>
