<template>
  <div class="passage-wrapper">
    <div class="passage">
      <h2 class="title">{{ passage.title }}</h2>
      <span class="subtitle">
        <span class="date">{{ releaseTime }}: {{ passage.date || '2019-4-18 12:35' }}</span>
      </span>
      <div class="content-wrapper">
        <span
          v-for="item in passage.content"
          :key="item.id"
          class="content"
        >
          <span
            v-if="item.subtitle"
            class="paragraph-subtitle"
          >
            {{ item.subtitle }}
          </span>
          <span
            v-if="item.paragraph"
            class="paragraph"
          >
            {{ item.paragraph }}
          </span>
          <img
            v-else-if="item.img"
            :src="item.img"
            class="inline-picture"
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { activitiesDetail } from '../meta'

  export default {
    name: 'Main',
    data() {
      return {}
    },
    computed: {
      passage() {
        const lang = 'zh-CN'

        return activitiesDetail[lang].find(item => item.id === this.$route.query.id)
      },
      releaseTime() {
        return '发布时间'
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .passage-wrapper
    background-color #fafafa
    margin-top 106px
    width 100%
    .passage
      width 940px
      padding 50px 100px
      margin 50px auto
      border-radius 8px
      background-color #fff
      display flex
      flex-direction column
      justify-content flex-start
      align-items flex-start
      .title
        margin 0 0 0 0
        font-size 32px
        line-height 45px
        color #333
      .subtitle
        margin 42px 0 0 0
        display flex
        flex-direction row
        justify-content flex-start
        align-items center
        .date
          font-size 16px
          line-height 22px
          color #666
        .line
          margin 0 48px 0 36px
          display inline-block
          height 20px
          width 1px
          background-color #4d4d4f
        .viewSum
          font-size 16px
          line-height 22px
          color #666
      .content-wrapper
        margin 26px 0 0 0
        width 740px
        font-size 16px
        line-height 32px
        color #333
        .content
          width 740px
          display flex
          flex-direction column
          justify-content flex-start
          align-items stretch
          position relative
          .paragraph-subtitle
            margin 30px 0 0 0
            width 100%
            text-align justify
            font-weight bold
          .paragraph
            margin 15px auto
            width 100%
            text-indent 32px
            text-align justify
          .inline-picture
            width 70%
            margin 20px auto
</style>
