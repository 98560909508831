<template>
  <div>
    <div class="office-list">
      <div
        v-for="(office, index) in officeList"
        :key="index"
        class="office"
        :class="{current: currentIndex === index}"
        @click="changeOffice(index)"
      ><span class="office-name">{{ office.name }}</span></div>
    </div>
    <div class="image-wrapper">
      <div
        v-for="(src, index) in currentImgs"
        :key="index"
        class="image-list"
        :style="{ backgroundImage: 'url(' + src + ')' }"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OfficeDisplay',
    props: {
      officeList: {
        type: Array,
        default: () => ([]),
      },
    },
    data() {
      return {
        currentIndex: 0,
      }
    },
    computed: {
      currentImgs() {
        if (this.officeList[this.currentIndex]) {
          return this.officeList[this.currentIndex].imgList
        }

        return []
      },
    },
    methods: {
      changeOffice(index) {
        this.currentIndex = index
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .office-list
    margin-bottom 50px
    display flex
    flex-direction row
    justify-content center
    align-items center
    .office
      width 216px
      height 76px
      text-align center
      border 1px solid #e6e6e6
      border-right none
      line-height 76px
      color #666
      font-size 16px
      background-color #fff
      cursor pointer
      &:last-child
        border-right 1px solid #e6e6e6
      &.current
      &:hover
        color #ff2441
        background-color #e6e6e6

  .image-wrapper
    display flex
    flex-direction row
    justify-content center
    align-items flex-start
    .image-list
      position relative
      width 320px
      height 298px
      display flex
      flex-direction column
      justify-content center
      align-items center
      background-repeat no-repeat
      background-size cover
      .title
        z-index 1
        font-size 26px
        line-height 37px
        font-weight bold
        font-family "PingFang SC", Helvetica, Arial, sans-serif
        color #fff
      .content
        z-index 1
        font-size 16px
        font-family "PingFang SC", Helvetica, Arial, sans-serif
        line-height 24px
        color #fff
        margin-top 21px
      .inner
        position absolute
        background-color rgba(0, 0, 0, 0.7)
        top 0
        left 0
        content ''
        width 100%
        height 100%
</style>
