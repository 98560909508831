<template>
  <div class="iphone">
    <div class="iphone-mp4">
      <video
        :poster="videoSelector.poster"
        :muted="ifMuted"
        loop
        class="my-video"
        :src="videoSelector.src"
        preload="auto"
      />
    </div>
    <div class="iphone-case" />
  </div>
</template>

<script>
  export default {
    name: 'Iphone',
    data() {
      return {
        ifMuted: true,
      }
    },
    computed: {
      videoSelector() {
        return {
          poster: '//ci.xiaohongshu.com/700a0de6-c9ee-4c1c-8e02-94e948d9f611',
          src: '//dc.xhscdn.com/a10d0710-80f1-11e9-9036-5948d2131083/new5.27.mp4',
        }
      },
    },
    mounted() {
      this.loadPhoneVideo()
    },
    methods: {
      loadPhoneVideo() {
        const phoneVideo = document.querySelector('.iphone-mp4 .my-video')

        phoneVideo.addEventListener('loadeddata', () => {
          if (phoneVideo.readyState >= 2) {
            phoneVideo.play()
          }
        })
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .iphone
    width 300px
    height 600px
    position relative
    .iphone-mp4
      position absolute
      width 260px
      height 564px
      margin 18px 20px
      .my-video
        width 260px
        height 564px
    .iphone-case
      position absolute
      width 300px
      height 600px
      background-image url("//ci.xiaohongshu.com/3ca6607e-d4a5-4cb9-b455-a746713d8283")
      background-repeat no-repeat
      background-size contain
  @media screen and (min-height: 900px)
    .iphone
      zoom 1.0
  @media screen and (min-height: 820px) and (max-height: 900px)
    .iphone
      zoom 0.9
  @media screen and (max-height: 820px)
    .iphone
      zoom 0.8
  @media screen and (max-height: 720px)
    .iphone
      zoom 0.7
  @media screen and (max-height: 540px)
    .iphone
      zoom 0.6
</style>
