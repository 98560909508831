<template>
  <div class="container">
    <NewBanner
      :img-src="imgSrc"
      :show-text="showText"
      :inner-style="{ backgroundColor: 'rgba(51, 51, 51, 0.3)' }"
    />
    <div class="trophy-wrapper">
      <h2 class="title">{{ showText.title }}</h2>
      <common-news-list :news-list="trophyContent" />
    </div>
  </div>
</template>

<script>

  import NewBanner from '../components/NewBanner.vue'
  import CommonNewsList from '../components/CommonNewsList.vue'
  import { trophys } from '../meta'

  export default {
    name: 'Trophy',
    components: {
      NewBanner,
      CommonNewsList,
    },
    data() {
      return {
        imgSrc: '//ci.xiaohongshu.com/89c9d9f2-7ae5-4aa3-91a3-0a6303a5369e',
      }
    },
    computed: {
      showText() {
        return {
          title: '公司荣誉',
        }
      },
      trophyContent() {
        const lang = 'zh-CN'
        return trophys[lang]
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .container
    padding-bottom 100px
    background-color #fff
  .trophy-wrapper
    display flex
    flex-direction column
    align-items center
    .title
      font-size 36px
      color #000
      margin 100px 0 50px 0
</style>
