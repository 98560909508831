<template>
  <div class="menu">
    <template
      v-for="(menu, index) in menuConfig"
      :key="index"
    >
      <div
        v-if="menu.path"
        class="menu-item"
      >
        <router-link
          :to="menu.path"
          active-class=""
          :class="{active: menu.active}"
        >{{ menu.name }}
        </router-link>
        <SubNav
          v-if="hasSubMenu(menu)"
          :menu="menu"
        />
      </div>
      <div
        v-else
        class="menu-item"
      >
        <a
          :href="menu.href"
          target="_blank"
          :class="{active: menu.active}"
        >{{ menu.name }}
        </a>
        <SubNav
          v-if="hasSubMenu(menu)"
          :menu="menu"
        />
      </div>

    </template>
  </div>
</template>

<script setup lang="ts">
  import useMenu, { MenuItem } from '~/config/menu.config'
  import SubNav from './SubNav.vue'

  const hasSubMenu = (menu: MenuItem) => menu?.children?.length

  const menuConfig = useMenu()

</script>

<style lang="stylus" scoped>
.menu
  display flex

.transparent
  .menu-item
    a
      color rgba(245, 245, 245, 0.6)
      &:hover
        color #f5f5f5
  .menu-item
    a.active
      color #f5f5f5

.menu-item
  position relative
  margin 0 10px
  display flex
  align-items center
  cursor pointer
  word-break keep-all
  a
    color $light_gray_1000
    padding 5px 10px

.menu-item
  &:first-child
    margin-left 0
  &:last-child
    margin-right 0
    .submenu-wrapper
      left -44px

  &:nth-child(3)
    .submenu-wrapper
      left -23px

  &:nth-child(4)
    .submenu-wrapper
      left -14px

.menu-item
  &:hover
    font-weight 500
    .submenu-wrapper
      opacity  1
      visibility visible
      transform translateY(100%)
  a.active
    font-weight 500
    color $light_red_400
</style>

<style lang="stylus" scoped>
@media screen and (max-width: 1160px)
  .menu-item
    margin 0 5px
    padding 0 5px
    a
      color $light_gray_1000
      padding 5px 5px
    &:last-child
      .submenu-wrapper
        left -49px
    &:nth-child(3)
      .submenu-wrapper
        left -28px

    &:nth-child(4)
      .submenu-wrapper
        left -19px
</style>
