<template>
  <div
    id="feedsContainer"
    class="feeds-container"
    v-bind="$attrs"
  >
    <slot />
  </div>
</template>

<style lang="stylus" scoped>
.feeds-container
  display flex
  align-items flex-start
  position relative
  transition width .5s

@media (min-width 0px) and (max-width 1160px)
  .feeds-container
    width 768px

@media (min-width 1160px) and (max-width 1365px)
  .feeds-container
    width 964px

@media (min-width 1366px)
  .feeds-container
    width 1160px
</style>
