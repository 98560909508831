<template>
  <div class="page-wrapper">
    <div class="toc">
      <h2 class="toc-title">{{ title }}</h2>
      <div v-html="toc" />
    </div>
    <div
      class="content"
      v-html="content"
    />
  </div>
</template>
<script>
  import { marked } from 'marked'
  import { getMarkdown } from '~/services/markdown'

  /* services */
  export default {
    name: 'Markdown',
    props: {
      url: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        title: '',
        toc: '',
        content: '',
      }
    },
    async mounted() {
      const { title, toc, content } = await getMarkdown(this.url)
      this.title = title
      this.content = marked(content)
      this.toc = marked(toc)
      this.$nextTick(this.observe)
    },
    methods: {
      observe() {
        const tocNodes = Array.from(document.querySelectorAll('.toc a'))
        const tocIdList = tocNodes.map(element => element.innerText.replace(/\//, '').toLowerCase())
        const observeElements = tocIdList.map(id => document.querySelector(`.content #${id}`))

        // 清空所有 active class
        const clearActiveClass = () => {
          tocNodes.forEach(node => node.classList.remove('active'))
        }

        // 进入视口，清空所有 active class，然后为 active element 添加 class
        const tocObserver = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const activeToc = tocNodes.find(node => node.innerText === entry.target.innerText)
              clearActiveClass()
              activeToc.classList.add('active')
            }
          })
        }, {
          rootMargin: '-20% 0% -20% 0%',
        })
        observeElements.forEach(element => tocObserver.observe(element))

        // toc 被点击时重置 IntersectionObserver 造成的 active class 副作用
        tocNodes.forEach(node => {
          node.addEventListener('click', e => {
            setTimeout(() => {
              clearActiveClass()
              e.target.classList.add('active')
            }, 60)
          })
        })
      },
    },
  }
</script>
<style lang="stylus" scoped>
.page-wrapper
  width 960px
  display flex
  justify-content space-around

.toc
  position fixed
  left calc((100vw - 960px) / 2)
  top 156px
  width 230px
  background #fff

  .toc-title
    font-size 16px
    color #000
    font-weight 600
    margin 15px

  >>> a
    color #555
    padding 0 15px
    margin 0
    display block
    border-left 2px solid #fff
    transition all .2s
    &:hover
      color #ff0036
      border-left 2px solid #ff0036
    &.active
      color #ff0036
      border-left 2px solid #ff0036
.content
  margin-left 250px
  background #fff
  width 690px
  flex-shrink 0
  padding 0 20px 20px
</style>
