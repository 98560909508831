<template>
  <div class="input-container">
    <label
      class="phone"
      :class="{error: loginStore.errors.phone}"
    >
      <span class="country-code">+86</span>
      <input
        v-model="loginStore.loginData.phone"
        placeholder="输入手机号"
        type="text"
        @input="formatPhone"
      >
      <reds-icon
        v-show="loginStore.loginData.phone"
        size="24"
        icon="clear"
        class="clear"
        fill="#fff"
        @click="loginStore.clearPhone"
      />
    </label>
    <div class="err-msg">{{ loginStore.errors.phone }}</div>
    <label
      class="auth-code"
      :class="{error: loginStore.errors.authCode}"
    >
      <input
        v-model="loginStore.loginData.authCode"
        type="number"
        placeholder="输入验证码"
      >
      <span
        class="code-button"
        :class="{active: loginStore.validPhone && !counter.counting.value}"
        @click="sendAuthCode"
      >{{ authCodeText }}</span>
    </label>
    <div class="err-msg">{{ loginStore.errors.authCode }}</div>
    <button
      class="submit"
      :class="{ active: (loginStore.validPhone && loginStore.validCode) }"
      @click="onLogin"
    >登录</button>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import RedsIcon from '@xhs/reds-icon-web'
  import { h5Toast } from '@xhs/reds-toast-web'
  import {
    getApiSnsWebV1LoginSendCode,
    getApiSnsWebV1LoginCheckCode,
    postApiSnsWebV1LoginCode,
  } from '@xhs/logan-services-pc-web'
  import { eaglet } from '@xhs/launcher-plugin-eaglet'
  import { tracker } from '@xhs/protobuf-sns-pc-web-tracker'
  import { useLoginStore } from '~/store/login'
  import { formatLoginPhone } from '~/utils/phone'
  import useCountDown from '~/composables/useCountDown'

  const emit = defineEmits<{(e: 'loginSuccess', from: 'verification_code'): void}>()
  const loginStore = useLoginStore()

  const counter = useCountDown({ time: 2 * 1000 })

  const authCodeText = computed(() => {
    if (counter.counting.value) {
      return `重新发送（${counter.current.value.minutes * 60 + counter.current.value.seconds}s）`
    }
    return '发送验证码'
  })

  const formatPhone = (e: Event) => {
    const formated = formatLoginPhone((e.target as HTMLInputElement).value)
    loginStore.loginData.phone = formated
  }

  const login = async (mobileToken: string) => {
    try {
      const { userId } = await postApiSnsWebV1LoginCode({
        mobileToken,
        zone: '86',
        phone: loginStore.loginData.phone.replace(/\s+/g, ''),
      })
      if (userId) {
        emit('loginSuccess', 'verification_code')
      }
    } catch (error: any) {
      h5Toast(error.message || '薯队长遇到了点小麻烦')
    }
  }

  // 验证验证码，验证成功调登录接口
  const validateCode = async () => {
    try {
      const { mobileToken } = await getApiSnsWebV1LoginCheckCode({
        params: {
          phone: loginStore.loginData.phone.replace(/\s+/g, ''),
          zone: '86',
          code: loginStore.loginData.authCode,
        },
      })
      loginStore.errors.authCode = ''
      if (mobileToken) {
        login(mobileToken)
      }
    } catch (error: any) {
      if (error.code === 400) {
        h5Toast(error.message || '薯队长遇到了点小麻烦')
        throw error
      } else if (error.name === 'HTTPBizError') {
        loginStore.errors.authCode = error.message
      } else {
        h5Toast(error.message || '薯队长遇到了点小麻烦')
        throw error
      }
    }
  }

  // 用户点击登录按钮
  const onLogin = async () => {
    if (!loginStore.validPhone) {
      return
    }
    eaglet.push(tracker[24449]())
    if (loginStore.agreed) {
      validateCode()
    } else {
      loginStore.toggleTooltip(true)
    }
  }

  // 用户点击发送验证码
  const sendAuthCode = async () => {
    if (!counter.counting.value && loginStore.validPhone) {
      eaglet.push(tracker[24451]())
      try {
        await getApiSnsWebV1LoginSendCode({
          params: {
            phone: loginStore.loginData.phone.replace(/\s+/g, ''),
            zone: '86',
          },
        })
        counter.reset(180 * 1000)
        counter.start()
        h5Toast('验证码已发送')
        loginStore.errors.phone = ''
      } catch (error: any) {
        if (error.code === 400) {
          h5Toast(error.message || '薯队长遇到了点小麻烦')
          throw error
        } else if (error.name === 'HTTPBizError') {
          loginStore.errors.phone = error.message
        } else {
          h5Toast(error.message || '薯队长遇到了点小麻烦')
          throw error
        }
      }
    }
  }

</script>

<style lang="stylus" scoped>
  .input-container
    margin-top 28px
    width 280px
    display flex
    flex-direction column

  .phone, .auth-code
    flex-center()
    font-size 18px
    line-height 22px
    color #333333
    height 40px
    border-bottom 0.5px solid rgba(0, 0, 0, 0.08)
    transition border-color .2s
    &.error
      border-color $light_red_400
    input
      flex 1
      font-size 18px
      width 140px
      font-weight 510
      height 100%
      caret-color $light_red_400
      &::placeholder
        font-size 16px
        color rgba(51, 51, 51, 0.3)
        font-weight 400

  .phone
    .country-code
      flex-center()
      position relative
      width 48px
      height 100%
      font-weight 510
      &::after
        position absolute
        right 0
        top 12px
        content ''
        width 0.5px
        height 16px
        background rgba(0, 0, 0, 0.08)

    input
      margin-left 16px

    .clear
      cursor pointer

  .code-button
    font-size 16px
    cursor pointer
    color rgba(255, 36, 66, 0.3)
    &.active
      color $light_red_400

  .err-msg
    margin-top 6px
    height 10px
    line-height 10px
    font-size 10px
    color $light_red_400

  .submit
    flex-center()
    margin-top 8px
    height 40px
    background rgba(0, 0, 0, 0.04)
    border-radius 20px
    font-size 16px
    font-weight 500
    color rgba(51, 51, 51, 0.2)
    cursor pointer
    transition all .2s
    &.active
      background $light_red_400
      color #fff

</style>
