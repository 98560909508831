<template>
  <div class="container">
    <new-banner
      class="banner"
      :img-src="imgSrc"
      :show-text="liability.showText"
      :inner-style="{ backgroundColor: 'rgba(51, 51, 51, 0.3)' }"
    />
    <div class="liability-wrapper">
      <liability-list :list="liabilityList" />
    </div>
  </div>
</template>

<script>
  import NewBanner from '~/pages/newsCenter/components/NewBanner.vue'
  import LiabilityList from './components/LiabilityList.vue'
  import { LIABILITY_LIST } from './meta'

  export default {
    name: 'Main',
    components: {
      NewBanner,
      LiabilityList,
    },
    data() {
      return {
        imgSrc: '//ci.xiaohongshu.com/cb1d76b4-a166-449c-a9b7-0f5806979546',
        innerStyle: {},
        bannerStyle: {
          height: '436px',
        },
        liabilityList: LIABILITY_LIST,
      }
    },
    computed: {
      liability() {
        return {
          showText: {
            title: '社会责任',
          },
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .liability-wrapper
    background-color #FAFAFA
    padding-bottom 50px
    margin-top 106px
</style>
