<template>
  <div class="container">
    <div
      v-for="(card, index) in cards"
      :key="index"
      class="card-wrapper"
    >
      <div class="img-card">
        <div
          class="card-front"
          :style="{ backgroundImage: 'url(' + card.imgSrc + ')'}"
        >
          <div class="mask-front">
            <span class="index">{{ `0${index + 1}` }}</span>
            <span class="tab">{{ card.tab }}</span>
          </div>
        </div>
        <div class="card-back">
          <div class="mask-back">
            <span class="index">{{ `0${index + 1}` }}</span>
            <span class="tab">{{ card.tab }}</span>
            <span class="line" />
            <span
              class="description"
              :class="[currentLang !== 'zh-CN' ? 'english' : 'chinese']"
            >{{ card.description }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FlipCards',
    props: {
      cards: {
        type: Array,
        default: () => ([]),
      },
    },
    computed: {
      currentLang() {
        return 'zh-CN'
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .container
    display flex
    flex-direction row
    justify-content center
    align-items center
    .card-wrapper
      width 240px
      height 350px
      .img-card
        position relative
        width 100%
        height 100%
        overflow hidden
      &:hover
        .card-front
          opacity 0
        .card-back
          opacity 1
          .mask-back
            transform scale(1)
      .card-front
      .card-back
        cursor pointer
        position absolute
        width 100%
        height 100%
      .card-front
        transition opacity 0.3s ease 0s
        background-repeat no-repeat
        background-size 100%
        .mask-front
          position absolute
          width 100%
          height 100%
          background-color rgba(255,255,255,0.6)
          opacity 0.8
          display flex
          flex-direction column
          justify-content center
          align-items center
          .index
            margin-bottom 20px
            color #000
            font-size 16px
            line-height 24px
          .tab
            color #333
            font-size 24px
            line-height 30px
      .card-back
        opacity 0
        transition opacity 0.6s ease 0s
        .mask-back
          transform scale(1.3)
          transition transform 0.8s ease 0s
          position absolute
          width 100%
          height 100%
          display flex
          flex-direction column
          justify-content center
          align-items center
          background-color #ff2442
          color #fff
          .index
            font-size 16px
            line-height 24px
          .tab
            margin-top 16px
            font-size 24px
            line-height 30px
          .line
            margin-top 20px
            margin-bottom 20px
            display inline-block
            width 49px
            height 1px
            border-radius 1px
            background-color rgba(255, 255, 255, 1)
            opacity 0.5
          .description
            width 200px
            font-size 14px
            line-height 25px
            &.english
              line-height 21px
              word-wrap break-word
              hyphens auto
</style>
