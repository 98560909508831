<template>
  <div class="ewm">
    <div
      class="smallewm"
      @mouseenter="enter"
      @mouseout="out"
    />
    <div
      v-show="bigewm"
      class="bigewm"
    >
      <img
        class="ewimg"
        src="//ci.xiaohongshu.com/0b84da4e-3984-4603-a2f2-72a806e5494d"
      >
      <p class="title">扫我下载App</p>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        bigewm: false,
      }
    },
    methods: {
      enter() {
        this.bigewm = true
      },
      out() {
        this.bigewm = false
      },
    },
  }
</script>
<style lang="stylus" scoped>
.smallewm
  position fixed
  right 10px
  bottom 80px
  width 30px
  height 30px
  background url('../assets/smallewm.png') no-repeat
  background-size contain
  &:hover
    background url('../assets/smallewm.png') no-repeat
    background-size contain
.bigewm
  background #fff
  position fixed
  right 50px
  bottom 10px
  width 100px
  height 120px
  position fixed
  box-shadow  0 2px 4px #eee
  border-radius 5px
  .ewimg
    width 86px
    height 86px
    margin 7px 7px 1px 7px
  .title
    font-size 13px
    color $GREY7
    margin 0
    text-align center
</style>
