<template>
  <section
    ref="noteEl"
    class="note-item"
    :style="style"
  >
    <a
      target="_blank"
      :href="`https://www.xiaohongshu.com/discovery/item/${note.id as string}`"
      class="cover"
      :class="{ld: imgLoaded}"
      :style="{
        height: `${getCoverHeight()}px`,
        background: `no-repeat url(${note.noteCard?.cover?.url}?imageView2${imgQuery}) left top 100% / 100%`
      }"
      @click="reportNoteClick"
    >
      <span
        v-if="note.noteCard?.type === 'video'"
        class="play-icon"
      >
        <reds-icon
          size="11"
          icon="play-s"
        />
      </span>
    </a>
    <div class="footer">
      <a
        v-if="note.noteCard?.displayTitle"
        target="_blank"
        :href="`https://www.xiaohongshu.com/discovery/item/${note.id as string}`"
        class="title"
        @click="reportNoteClick"
      ><NoteTitle
        :support-webp="supportWebp"
        :content="note.noteCard?.displayTitle"
      /></a>
      <div class="author-wrapper">
        <a
          class="author"
          target="_blank"
          :href="`https://www.xiaohongshu.com/user/profile/${note.noteCard?.user?.userId as string}`"
          @click="reportAvatarClick"
        >
          <img
            class="author-avatar"
            :src="`${note.noteCard?.user?.avatar}?imageView2/2/w/60/format/${supportWebp ? 'webp' :'jpg'}`"
          >
          <span class="name">{{ note.noteCard?.user?.nickName }}</span>
        </a>
        <div
          class="like"
          :class="{active: isPlayLike}"
          @click="onLike(note)"
        >
          <span
            ref="likeEl"
            class="like-lottie"
          />
          <reds-icon
            size="16"
            class="like-icon"
            :icon="note.noteCard?.interactInfo?.liked ? 'liked' : 'like'"
          />
          <span class="num">
            {{ loggedIn ? formatLikeNum(note.noteCard?.interactInfo?.likedCount) : note.noteCard?.interactInfo?.likedCount }}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
  import {
    onMounted, reactive, ref, CSSProperties, nextTick,
  } from 'vue'
  import lottie from 'lottie-web'
  import RedsIcon from '@xhs/reds-icon-web'
  import { eaglet } from '@xhs/launcher-plugin-eaglet'
  import { tracker } from '@xhs/protobuf-sns-pc-web-tracker'
  import { h5Toast } from '@xhs/reds-toast-web'
  import { postApiSnsWebV1NoteLike, postApiSnsWebV1NoteDislike } from '@xhs/logan-services-pc-web'
  import debounce from 'lodash.debounce'
  import { IPosition } from '~/types/feeds'
  import { IFeed } from '~/store/feed'
  import { loadImage } from '~/utils/common'
  import { formatLikeNum } from '~/utils/note'
  import NoteTitle from './NoteTitle.vue'
  import useGoLogin, { LoginFrom } from '~/composables/useGoLogin'
  import likeLottie from '~/assets/lottie_like.json'
  import dislikeLottie from '~/assets/lottie_like_cancel.json'

  import '~/assets/like.svg'
  import '~/assets/liked.svg'
  import '~/assets/play-s.svg'

  const noteTypeMap = {
    video: 3,
    normal: 1,
  }

  const props = defineProps<{
    note: IFeed
    columns: number
    getPosition:(height: number, index: number) => IPosition
    position: IPosition | undefined
    prevPosition: IPosition | undefined
    loggedIn: boolean
    supportWebp: boolean
  }>()
  const goLogin = useGoLogin()
  const isPlayLike = ref(false)
  const previewQuery = `/2/w/30/q/10!/format/${props.supportWebp ? 'webp' : 'jpg'}`
  const hdQuery = `/2/w/540/format/${props.supportWebp ? 'webp' : 'jpg'}`
  const noteEl = ref<HTMLElement>()
  const likeEl = ref<HTMLElement>()
  const imgLoaded = ref(!!props.note.exposed)
  const imgQuery = ref(imgLoaded.value ? hdQuery : previewQuery)
  const style = reactive<CSSProperties>({})

  if (props.position) {
    style.transform = `translate(${props.position.left}px, ${props.position.top}px)`
  }

  const trackerData = {
    objectPosition: props.note.index,
    noteId: props.note.id,
    // @ts-ignore
    noteType: noteTypeMap[props.note.noteCard?.type] || 0,
    // @ts-ignore
    trackId: props.note.trackId,
  }

  const getCoverHeight = () => {
    const note = props.note
    if (note.noteCard?.cover?.width && note.noteCard?.cover?.height) {
      return Math.round(180 / note.noteCard.cover.width * note.noteCard.cover.height)
    }
    return 0
  }

  const createObserver = () => new IntersectionObserver((entries, observer) => {
    if (entries[0].isIntersecting) {
      if (props.note.exposed) {
        return
      }
      eaglet.push(tracker[25302](trackerData))
      // eslint-disable-next-line
      props.note.exposed = true
      observer.disconnect()
    }
  }, { threshold: [0.5, 1] })

  const reportNoteClick = () => {
    eaglet.push(tracker[25303](trackerData))
  }

  const reportAvatarClick = () => {
    eaglet.push(tracker[25305](trackerData))
  }

  const loadHDImg = async () => {
    if (!props.note.noteCard?.cover?.url) {
      return
    }
    await loadImage(`${props.note.noteCard.cover.url}?imageView2${hdQuery}`)
    imgQuery.value = hdQuery
    nextTick(() => {
      imgLoaded.value = true
    })
  }

  const playLikeAnimation = (like: boolean) => {
    isPlayLike.value = true
    const ani = lottie.loadAnimation({
      container: likeEl.value as HTMLElement,
      loop: false,
      name: 'like',
      animationData: like ? likeLottie : dislikeLottie,
    })
    ani.addEventListener('complete', () => {
      isPlayLike.value = false
      ani.destroy()
    })
  }

  const onLike = debounce(async (note: IFeed) => {
    if (isPlayLike.value) {
      return
    }

    const liked = note.noteCard?.interactInfo?.liked
    const likedCount = note.noteCard?.interactInfo?.likedCount
    playLikeAnimation(!liked)
    eaglet.push(tracker[liked ? 25362 : 25361](trackerData))
    const method = liked ? postApiSnsWebV1NoteDislike : postApiSnsWebV1NoteLike
    try {
      await method({ noteOid: note.id as string })
      if (note.noteCard?.interactInfo) {
        note.noteCard.interactInfo.liked = !liked
        // @ts-ignore
        note.noteCard.interactInfo.likedCount = liked ? +likedCount - 1 : +likedCount + 1
      }
      eaglet.push(tracker[liked ? 25328 : 25327](trackerData))
    } catch (error: any) {
      if (error.code === -104) {
        goLogin(LoginFrom.NoAuth)
        return
      }
      h5Toast(error.message || '薯小虎遇到了点小麻烦')
      throw error
    }
  }, 500, { leading: true, trailing: false })

  /**
   * 让元素按顺序渲染，当前元素计算位置，需要等待上一个计算完成
   * 否则靠后的笔记可能会出现在前面，导致虚拟滚动位置更新出问题
   */
  const calcPosition = () => {
    if (props.position || !noteEl.value) {
      return
    }

    const index = props.note.index
    if (index === 0 || props.prevPosition) {
      const { left, top } = props.getPosition(noteEl.value.clientHeight, index)
      style.transform = `translate(${left}px, ${top}px)`
    } else {
      nextTick(calcPosition)
    }
  }

  onMounted(async () => {
    calcPosition()
    if (!props.note.exposed) {
      const exposeObserver = createObserver()
      exposeObserver.observe(noteEl.value as HTMLElement)
      loadHDImg()
    }
  })
</script>

<style lang="stylus" scoped>
.note-item
  position absolute
  left 0
  top 0
  width 180px

.cover
  position relative
  width 180px
  display flex
  border-radius 8px
  overflow hidden
  outline 0.5px solid rgba(0, 0, 0, 0.05)
  transition background .2s
  &::before
    content ''
    absolute-fill()
    background rgba(233, 233, 233, 0.5)
    backdrop-filter blur(42.5px)
    z-index 1
    transition all 400ms
    border-radius 8px
  &.ld::before
    background transparent
    backdrop-filter blur(0px)
  &::after
    content ''
    absolute-fill()
    transition background-color .2s
    background-color transparent
    -webkit-transform: translate3d(0,0,0)
    border-radius 8px
  &:hover
    &::after
      background-color rgba(0, 0, 0, 0.3)

  img
    width 100%

  .play-icon
    flex-center()
    position absolute
    right 14px
    top 14px
    width 20px
    height 20px
    background rgba(0, 0, 0, .3)
    border-radius 20px

.footer
  padding 12px

.title
  margin-bottom 8px
  line-clamp(2)
  font-weight 500
  font-size 14px
  line-height 22px
  color #333333

.author-wrapper
  display flex
  align-items center
  justify-content space-between
  height 20px
  color #666666
  font-size 12px
  transition color 1s
  .author
    display flex
    align-items center
    color inherit
    &:hover
      color: #333333
  .author-avatar
    margin-right 6px
    width 20px
    height 20px
    border-radius 20px
    border 0.5px solid #E6E6E6

  .name
    line-clamp(1)
    max-width 72px

.like
  position relative
  flex-center()
  cursor pointer
  &:hover
    color #333333
  &.active
    .like-icon
      visibility hidden

  .like-lottie
    position absolute
    left -5px
    top -5px
    width 26px
    height 26px

.num
  line-clamp(1)
  margin-left 2px
  max-width 40px

</style>
