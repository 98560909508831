<template>
  <FeedsLayout>
    <div class="skeleton-container">
      <img
        v-for="item in minRenderNotes"
        :key="item"
        v-bind="getAttr(item)"
      >
    </div>
  </FeedsLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import FeedsLayout from '~/components/Feeds/FeedsLayout.vue'
  import useWaterfallLayout from '~/components/Feeds/composables/useWaterfallLayout'
  import { Images } from '~/consts/images'
  import { IPosition } from '~/types/feeds'

  const notePositions = ref<IPosition[]>([])
  const containerHeight = ref(0)
  const props = defineProps({
    minRenderNotes: {
      type: Number,
      default: 60,
    },
    columns: {
      type: Number,
      default: 6,
    },
  })

  const columns = ref(props.columns)

  const {
    getItemPosition,
  } = useWaterfallLayout(
    columns,
    notePositions,
    containerHeight,
  )

  const skeletonMap = {
    0: Images.SkeletonS,
    1: Images.SkeletonM,
    2: Images.SkeletonL,
  }

  const heightMap = {
    0: 187,
    1: 232,
    2: 292,
  }

  const getAttr = (index: number) => {
    const i = (index % 3) as 0 | 1 | 2
    const style = getItemPosition(heightMap[i], index - 1)
    return {
      src: skeletonMap[i],
      style: {
        transform: `translate(${style.left}px, ${style.top}px)`,
      },
    }
  }

</script>

<style lang="stylus" scoped>
.skeleton-container
  position relative
  width 100%
  height calc(100vh - 88px)
  overflow hidden
img
  position absolute
  left 0
  top 0
  width 180px
</style>
