<template>
  <div>
    <new-banner
      class="banner"
      :img-src="imgSrc"
      :show-text="aboutUs.showText"
      :inner-style="{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }"
    />
    <div class="content">
      <div class="introduction">
        <span class="title">{{ aboutUs.introduction.title }}</span>
        <span
          class="description-wrapper"
          :class="['chinese']"
        >
          <p
            v-for="(para, index) in aboutUs.introduction.detail"
            :key="index"
            class="description"
          >{{ para }}</p>
        </span>
        <flip-cards :cards="aboutUs.otherTabs" />
        <ambition-list
          class="ambition-list"
          :ambition-list="aboutUs.ambitionList"
        />
      </div>
      <div class="context">
        <span class="title">{{ aboutUs.officeEnvironment }}</span>
        <office-display :office-list="aboutUs.officeList" />
      </div>
      <div class="growth">
        <span class="title">{{ aboutUs.growthTitle }}</span>
        <growth-history :growth-history="aboutUs.growthHistory" />
      </div>
      <div class="cooperation">
        <span class="title">{{ aboutUs.contactUs }}</span>
        <div class="mails">
          <span
            v-for="(mail, index) in aboutUs.mails"
            :key="index"
            class="mail"
          ><span class="mail-name">{{ mail.name }}</span><span class="mail-mail">{{ mail.mail }}</span></span>
        </div>
      </div>
      <div class="address">
        <div class="address-container">
          <div
            class="office-address-wrapper"
            :class="['chinese']"
          >
            <span class="title">{{ aboutUs.officeAddressTitle }}</span>
            <div
              v-for="(office, index) in aboutUs.officeAddress"
              :key="index"
              class="office"
            >
              <span class="office-name">{{ office.name }}</span>
              <span class="office-address">{{ office.address }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="ceo-mail-box">
        <span class="title">{{ aboutUs.ceoMailBox.title }}</span>
        <div class="paragraphs-container">
          <div
            v-for="para in aboutUs.ceoMailBox.paragraphs"
            :key="para.id"
            class="paragraph"
          >
            <p
              class="content"
              v-html="para.content"
            />
            <ul
              v-if="para.list"
              class="list"
            >
              <li
                v-for="item in para.list"
                :key="item.key"
                class="list-item"
              >{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="contact">
        <span class="title">{{ aboutUs.contactTitle }}</span>
        <qrcodes :qrcodes="aboutUs.qrcodes" />
      </div>
    </div>
  </div>
</template>

<script>

  import NewBanner from '~/pages/newsCenter/components/NewBanner.vue'
  import Qrcodes from '~/components/QrCodes.vue'

  import GrowthHistory from './components/GrowthHistory.vue'
  import AmbitionList from './components/AmbitionList.vue'
  import FlipCards from './components/FlipCards.vue'
  import OfficeDisplay from './components/OfficeDisplay.vue'

  import {
    ambitionList,
    otherTabs,
    officeList,
    mails,
    officeAddress,
    growthHistory,
    qrcodes,
    ceoMailBox,
  } from './meta'

  export default {
    name: 'Main',
    components: {
      GrowthHistory,
      NewBanner,
      AmbitionList,
      FlipCards,
      OfficeDisplay,
      Qrcodes,
    },
    data() {
      return {
        imgSrc: '//ci.xiaohongshu.com/8e5fc61a-b0b0-435d-8000-ff42a2533e6b',
        context: {
          title: '公司环境',
          detail: '干净、清新、梦幻，为你提供',
        },
        cooperation: {
          top: 'COOPERATIONS',
          title: '合作邮箱',
        },
        address: {
          top: 'ADDRESS',
          title: '我们在这里',
        },
        addressIcon: '//ci.xiaohongshu.com/1747c6ce-f616-4727-870a-c92c08d6ec7b',
        contact: {
          top: 'CONTACT',
          title: '更多方式关注小红书',
        },
      }
    },
    computed: {
      aboutUs() {
        const lang = 'zh-CN'
        return {
          ambitionList: ambitionList[lang],
          otherTabs: otherTabs[lang],
          officeList: officeList[lang],
          mails: mails[lang],
          officeAddress: officeAddress[lang],
          growthHistory: growthHistory[lang],
          qrcodes: qrcodes[lang],
          ceoMailBox: ceoMailBox[lang],
          showText: {
            title: '关于我们',
          },
          introduction: {
            title: '什么是小红书',
            detail: [
              '小红书是年轻人的生活方式平台，由毛文超和瞿芳于2013年在上海创立。小红书以“Inspire Lives 分享和发现世界的精彩”为使命，用户可以通过短视频、图文等形式记录生活点滴，分享生活方式，并基于兴趣形成互动。截至到2019年10月，小红书月活跃用户数已经过亿，其中70%用户是90后，并持续快速增长。',
            ],
          },
          officeEnvironment: '公司环境',
          contactUs: '合作邮箱',
          officeAddressTitle: '我们在这里',
          growthTitle: '小红书发展史',
          contactTitle: '更多方式关注小红书',
        }
      },
    },
    methods: {
      goToMap(city) {
        const URL = 'https://uri.amap.com/marker?position='
        const obj = {
          Shanghai: '121.474731,31.215878',
          Beijing: '116.488539,40.002106',
          Wuhan: '114.421449,30.472741',
        }

        window.open(URL + obj[city], '_blank')
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .title
    margin-bottom 50px
    font-size 36px
    line-height 50px
    font-weight bold
    color #000
  .introduction
    background-color #fff
    padding-top 96px
    padding-bottom 75px
    display flex
    flex-direction column
    justify-content center
    align-items center
    .description-wrapper
      display inline-block
      vertical-align top
      width 960px
      color #555
      font-weight 400
      font-size 16px
      line-height 24px
      text-align center
      margin-bottom 50px
      &.english
        text-align justify
      .description
        text-align justify
        text-align-last left
        margin 0
        padding 0
        margin-top 24px
        &:first-child
          margin-top 0px
    .ambition-list
      margin 44px auto 25px auto
  .context
    background-color rgba(251,251,251,1)
    display flex
    flex-direction column
    justify-content center
    align-items center
    padding-top 100px
    padding-bottom 100px
    .detail-wrapper
      margin-bottom 30px
  .cooperation
    background-color #fbfbfb
    display flex
    flex-direction column
    justify-content center
    align-items center
    padding-top 100px
    padding-bottom 100px
  .address
    background-color #fff
    overflow hidden
    display flex
    flex-direction row
    justify-content center
    align-items center
    .address-container
      width 1440px
      position relative
      padding-top 100px
      padding-bottom 100px
      height 500px
      .office-address-wrapper
        width 960px
        margin 0 auto
        .title
          display inline-block
          line-height 39px
          margin-bottom 53px
        &.english
          .title
            font-size 26px
        .office
          margin-bottom 30px
          text-align left
          font-size 16px
          line-height 20px
          color #555
          .office-name
            display block
            color #000
            font-weight 500
            margin-bottom 10px
          .office-address
            display block
            width 362px
            line-height 20px
      .map
        position absolute
        right 0
        top 0
        padding 0
        margin 0
        width 780px
        height 500px
        background-size contain
        background-repeat no-repeat
        img
          width 30px
          height 30px
          position absolute
          cursor pointer
        .shanghai
          top 250px
          right 355px
        .beijing
          top 100px
          right 421px
        .wuhan
          top 250px
          right 476px
  .growth
    padding 100px 0
    background-color #fff
    display flex
    flex-direction column
    justify-content center
    align-items center
  .contact
    padding-top 100px
    padding-bottom 100px
    text-align center
    background-color #fff
    .title
      display inline-block
      margin-bottom 50px
  .title-wrapper
    width 960px
    margin 0 auto
  .ceo-mail-box
    padding 100px 0
    background-color #fbfbfb
    display flex
    flex-direction column
    justify-content flex-start
    align-items center
    color #333
    .title
      display inline-block
      font-size 36px
      line-height 39px
      margin-bottom 50px
    .paragraphs-container
      display flex
      flex-direction column
      justify-content flex-start
      align-items flex-start
      position relative
      &:before
        content ''
        position absolute
        width 16px
        height 14px
        top -10px
        left -19px
        background-image url("//ci.xiaohongshu.com/9122eb19-33ca-4a92-a56a-1cf7cbf6d6fc")
        background-size contain

      .paragraph
        width 1021px
        margin-bottom 30px
        &:last-child
          margin-bottom 0
        .content
          font-size 20px
          line-height 30px
          margin 0
          padding 0
        .list
          margin 0
          margin-top 15px
          padding 0
          list-style none
          color #555
          .list-item
            width 1002px
            font-size 16px
            line-height 19px
            margin 0 0 15px 19px
            position relative
            &:before
              content ''
              position absolute
              width 4px
              height 4px
              border-radius 4px
              overflow hidden
              background-color #ff2442
              left -7.5px
              top 7.5px
            &:last-child
              margin-bottom 0
  .mails
    width 960px
    margin 0 auto
    display flex
    flex-direction row
    flex-wrap wrap
    justify-content flex-start
    align-items flex-start
    margin-bottom -30px
    .mail
      width 240px
      height 50px
      margin-bottom 30px
      display flex
      flex-direction column
      justify-content space-between
      align-items flex-start
      .mail-name
        font-size 16px
        line-height 20px
        color #000
        font-weight bold
      .mail-mail
        font-size 14px
        line-height 20px
        color #555
  @media screen and (min-width: 1024px) and (max-width: 1440px)
    .address
      .address-container
        padding-top 49px
        padding-bottom 49px
        width 100%
        height 400px
        .office-address-wrapper
          &.english
            .title
              margin-bottom 23px
            .office
              margin-bottom 20px
        .map
          width 624px
          height 400px
          .shanghai
            top 194px
            right 284px
          .beijing
            top 74px
            right 336.8px
          .wuhan
            top 200px
            right 380.8px
  @media screen and (max-width: 1024px)
    .address
      .address-container
        padding-top 24px
        padding-bottom 24px
        width 100%
        height 350px
        .office-address-wrapper
          &.english
            .title
              margin-bottom 23px
            .office
              margin-bottom 20px
        .map
          width 546px
          height 350px
          .shanghai
            top 165px
            right 245.5px
          .beijing
            top 60px
            right 290.7px
          .wuhan
            top 175px
            right 333.2px
</style>
