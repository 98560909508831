<template>
  <div
    class="oneBanner"
    :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
  >
    <div
      class="inner"
      :style="innerStyle"
    />
    <span class="title">{{ showText.title }}</span>
    <span
      v-if="showText.content"
      class="line"
    />
    <span
      v-if="showText.content"
      class="content"
    >{{ showText.content }}</span>
  </div>
</template>

<script>
  export default {
    name: 'NewBanner',
    props: {
      imgSrc: {
        type: String,
        default: '',
      },
      showText: {
        type: Object,
        default: () => ({}),
      },
      innerStyle: {
        type: Object,
        default: () => ({}),
      },
      bannerStyle: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {

      }
    },
  }
</script>

<style lang="stylus" scoped>
  .oneBanner
    width 100%
    height 500px
    position relative
    margin 0 auto 0 auto
    background-size cover
    background-position center center
    background-repeat no-repeat
    display flex
    flex-direction column
    justify-content center
    align-items center
    .title
      max-width 100%
      font-size 48px
      line-height 67px
      font-weight bold
      font-family "PingFang SC", Helvetica, Arial, sans-serif
      color #fff
      position relative
    .line
      max-width 100%
      border-radius 1px
      margin 16px 0 25px 0
      display inline-block
      width 204px
      height 1px
      background-color #fff
    .content
      max-width 100%
      z-index 1
      font-size 24px
      font-family "PingFang SC", Helvetica, Arial, sans-serif
      line-height 33px
      font-weight normal
      color #fff
    .inner
      max-width 100%
      position absolute
      top 0
      left 0
      content ''
      width 100%
      height 100%
</style>
