body
  font-size 14px
  color #333333
  // 覆写 reds-style
  width 100vw !important
  max-width 100%
  min-width 960px
  overflow-x scroll
  font-family system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", "Segoe UI", "Arial", "PingFang SC", "PingFang TC", "PingFang HK", "Microsoft Yahei", "Microsoft JhengHei", sans-serif

*
  box-sizing border-box
  user-select auto


h1
  font-size 48px

h2
  font-size 36px

h3
  font-size 26px

h4
  font-size 20px

input, button
  appearance none
  outline none
  border none
  background none
  padding 0

// 隐藏 safari autoinput 后的黄色背景
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

// 隐藏 input number 的上下箭头
input[type=number]
  -moz-appearance textfield
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance none
  margin 0

button
  padding 0
