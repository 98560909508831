<template>
  <div class="event-wrapper">
    <div class="selector-wrapper">
      <div
        class="left arrow"
        @click="showPrevYear"
      ><growth-arrow-left /></div>
      <template v-for="(item, index) in growthHistory">
        <div
          v-if="ifShowing(index)"
          :key="index"
          class="selector"
          :class="{ current: index === currentIndex }"
          @click="showCurrentYear(index)"
        >
          {{ item.year }}
        </div>
      </template>
      <div
        class="right arrow"
        @click="showNextYear"
      ><growth-arrow-right /></div>
    </div>
    <div class="breaking-news">
      <h2 class="year">{{ growthHistory[currentIndex].year + yearsuffix() }}</h2>
      <div class="item-wrapper">
        <div
          v-for="(item, index) in growthHistory[currentIndex].events"
          :key="index"
          class="each-item"
          :class="{ selected: index === currentMonth }"
        >
          <span
            class="month"
            :class="{ followers: isFollowers(index) }"
          >{{ item.month }}</span>
          <span class="progress">
            <span class="dot" />
            <i class="line">
              <i class="process" />
            </i>
          </span>
          <span
            class="content"
            :class="{hoverable: item.src}"
            @click="viewContent(item)"
          >{{ item.news }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import growthArrowLeft from './images/growthArrowLeft.vue'
  import growthArrowRight from './images/growthArrowRight.vue'

  export default {
    name: 'GrowthHistory',
    components: {
      growthArrowLeft,
      growthArrowRight,
    },
    props: {
      limit: {
        type: Number,
        default: 4,
      },
      growthHistory: {
        type: Array,
        default: () => ([]),
      },
    },
    data() {
      return {
        currentIndex: 0,
        currentMonth: 0,
        task: '',
        noChange: false,
        arrOfGrowthArr: [],
      }
    },
    mounted() {
      this.currentIndex = this.growthHistory.length - 1
      this.play()
    },
    methods: {
      yearsuffix() {
        return '年大事件'
      },
      ifShowing(index) {
        if (!this.noChange) {
          this.changeArrBoolean(index)
        }

        return this.arrOfGrowthArr[index] || false
      },
      changeArrBoolean(index) {
        const arrLength = this.growthHistory.length
        const halfLimit = Math.round(this.limit / 2)

        if (this.currentIndex >= halfLimit && this.currentIndex <= arrLength - halfLimit) {
          this.arrOfGrowthArr[index] = this.currentIndex - index <= halfLimit && this.currentIndex - index > -halfLimit
          return
        }

        const spaceLeft = arrLength - this.currentIndex - 1

        if (spaceLeft <= halfLimit) {
          this.arrOfGrowthArr[index] = index > arrLength - this.limit - 1
          return
        }

        this.arrOfGrowthArr[index] = index < this.limit
      },
      showPrevYear() {
        if (this.currentIndex !== 0) {
          this.noChange = false
          this.currentIndex -= 1
          this.initInterval(this.task)
          this.play()
        }
      },
      showCurrentYear(index) {
        this.noChange = true
        this.currentIndex = index
        this.initInterval(this.task)
        this.play()
      },
      showNextYear() {
        if (this.currentIndex !== this.growthHistory.length - 1) {
          this.noChange = false
          this.currentIndex += 1
          this.initInterval(this.task)
          this.play()
        }
      },
      isFollowers(index) {
        if (index === 0) {
          return false
        }

        const currentMonth = this.growthHistory[this.currentIndex].events[index].month
        const previousMonth = this.growthHistory[this.currentIndex].events[index - 1].month

        return currentMonth === previousMonth
      },
      initInterval(task) {
        this.currentMonth = 0
        clearInterval(task)
      },
      autoPlay() {
        this.currentMonth = this.currentMonth === this.growthHistory[this.currentIndex].events.length - 1 ? 0 : this.currentMonth + 1
      },
      play() {
        this.task = setInterval(this.autoPlay, 2000)
      },
      viewContent(news) {
        if (news.src) {
          window.open(news.src, '_blank')
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .hoverable
    cursor pointer
  .event-wrapper
    width 1000px
    display flex
    flex-direction column
    justify-content flex-start
    align-items center
  .selector-wrapper
    width 1000px
    display flex
    margin-bottom 50px
    flex-direction row
    justify-content center
    align-items center
    color #666
    font-size 16px
    font-weight 16px
    line-height 76px
    text-align center
    cursor pointer
    .arrow
      width 70px
      height 76px
      border 1px solid #E6E6E6
      color #000
    .selector
      width 216px
      height 76px
      border 1px solid #E6E6E6
      border-right none
      &:first-child
        border-left none
      &:last-child
        border-right 1px solid #e6e6e6
      &.current
        color #ff2741
        background-color #F4F4F4
  .breaking-news
    width 864px
    padding-left 100px
    padding-right 50px
    display flex
    flex-direction column
    justify-content flex-start
    align-items flex-start
    .year
      font-size 20px
      line-height 28px
      color #FF2741
      font-weight normal
      margin 0 0 30px 70px
  .item-wrapper
    display flex
    width 600px
    flex-direction column
    justify-content flex-start
    align-items flex-start
  .each-item
    color #555
    display flex
    flex-direction row
    justify-content center
    align-items flex-start
    margin-bottom 4px
    .month
      display inline-block
      text-align right
      width 100px
      margin-right 18px
      font-size 16px
      line-height 24px
      font-weight 500
      color #000
      &.followers
        color #fff
    .progress
      display inline-block
      .dot
        display block
        width 4px
        height 4px
        border-radius 50%
        background-color #ccc
        margin 10px auto 0 auto
      .line
        display block
        width 1px
        height 45px
        background-color #eee
        margin 10px auto 0 auto
    .content
      display inline-block
      margin-left 30px
      width 628px
      white-space pre-wrap
      overflow hidden
      font-size 14px
      line-height 24px
      font-weight normal
    &:last-child
      .line
        background-color #fff
      &.selected
        .process
          background-color #fff
    &.selected
      color #000
      .process
        display block
        background-color #FF2741
        transition width 0.8s ease
        animation time 2s infinite
        @keyframes time
          0%
            height 0
          100%
            height 45px
      .dot
        background-color #FF2741
      .content
        color #ff2442
</style>
