<template>
  <FeedsLayout
    :style="{
      height: containerHeight === 0 ? 'auto' : containerHeight + 'px',
      visibility: visible ? 'visible': 'hidden'
    }"
  >
    <!-- key `id+index` 防止重复笔记 -->
    <Note
      v-for="note in renderList"
      :key="note.id + note.index"
      :note="note"
      :columns="columns"
      :position="notePositions[note.index]"
      :prev-position="notePositions[note.index - 1]"
      :get-position="getPosition"
      :logged-in="userStore.loggedIn"
      :support-webp="supportWebp"
    />
  </FeedsLayout>
  <div
    v-if="feeds.length > 0"
    class="loading"
    :class="{active: feeds.length > 0 && feedStore.isFetching && !feedStore.isError}"
  >
    <Loading :size="30" />
    <span>加载中</span>
  </div>
  <div
    class="back-top"
    :class="{active: active}"
    @click="handleBack2TopClick"
  >
    <reds-icon
      size="24"
      icon="arrow_back"
    />
  </div>
</template>

<script setup lang="ts">
  import {
    ref, onMounted, watch, defineAsyncComponent, onBeforeMount,
  } from 'vue'
  import { storeToRefs } from 'pinia'
  import RedsIcon from '@xhs/reds-icon-web'
  import { useGlobalStore } from '~/store/global'
  import { useFeedStore } from '~/store/feed'
  import { useUserStore } from '~/store/user'
  import FeedsLayout from '~/components/Feeds/FeedsLayout.vue'
  import Note from '~/components/Note/Index.vue'
  import useLayoutInfo from './composables/useLayoutInfo'
  import useWaterfallLayout from './composables/useWaterfallLayout'
  import useVirtualList from './composables/useVirtualList'
  import useBack2Top from './composables/useBack2Top'
  import { IPosition } from '~/types/feeds'
  import '~/assets/arrow_back.svg'

  const { supportWebp } = useGlobalStore()
  const { active, handleBack2TopClick } = useBack2Top()
  const Loading = defineAsyncComponent(() => import('~/components/Loading.vue'))
  const feedStore = useFeedStore()
  const userStore = useUserStore()
  const { feeds } = storeToRefs(feedStore)
  const containerHeight = ref<number>(0)
  const notePositions = ref<IPosition[]>([])
  const { columns, minRenderNotes } = useLayoutInfo()
  // SSR 是插入脚本对笔记进行布局，一开始会挤在一起
  // 详见 server/utils/layout.ts
  const visible = ref(false)

  onBeforeMount(() => {
    visible.value = true
  })
  // 用户登录后，拿到真实点赞数
  watch(() => userStore.loggedIn, val => {
    if (val && feeds.value.length > 0) {
      feedStore.getLikeNum()
    }
  })

  const onListEnd = () => {
    feedStore.fetchFeeds(columns.value * 4)
  }

  const {
    getItemPosition,
  } = useWaterfallLayout(
    columns,
    notePositions,
    containerHeight,
  )

  const {
    renderList,
    resetRenderList,
  } = useVirtualList<typeof feeds.value>(
    minRenderNotes,
    notePositions,
    feeds,
    columns,
    onListEnd,
  )

  watch(() => columns.value, () => {
    document.documentElement.scrollTop = 0
    notePositions.value = []
    containerHeight.value = 0
    resetRenderList()
  })

  // 为子组件获取位置信息
  const getPosition = (height: number, index: number) => {
    const { totalHeight, ...position } = getItemPosition(height, index)
    containerHeight.value = totalHeight
    return position
  }

  // SSR 拿到的 feeds 数量如果不够
  const fetchEnoughFeeds = async () => {
    if (feeds.value.length > 0 && feeds.value.length < minRenderNotes.value) {
      await feedStore.fetchFeeds(columns.value * 2)
      fetchEnoughFeeds()
    }
  }

  onMounted(async () => {
    fetchEnoughFeeds()
  })
</script>

<style lang="stylus" scoped>
.back-top
  flex-center()
  position fixed
  bottom 100px
  right 60px
  width 44px
  height 44px
  border 0.5px solid rgba(0, 0, 0, 0.06)
  box-shadow 0px 1px 12px rgba(0, 0, 0, 0.04)
  border-radius 44px
  cursor pointer
  opacity 0
  transition all .3s
  background #fff
  &:hover
    background rgb(240,240,240)
  &:active
    background rgb(224,224,224)
  &.active
    visibility visible
    opacity 1

.loading
  flex-center()
  width 100%
  height 64px
  font-size 12px
  color rgba(51, 51, 51, 0.6)
  visibility hidden
  &.active
    visibility visible
</style>
