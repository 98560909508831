<template>
  <!-- TODO launcher SSR teleport 不支持 -->
  <div to="body">
    <transition name="fade">
      <reds-modal
        v-model:open="loginStore.showLogin"
        @clickMask="onClose"
      >
        <div class="login-container">
          <reds-icon
            size="24"
            icon="close"
            class="close-button"
            @click="onClose"
          />
          <div class="left">
            <img
              class="logo"
              :src="Images.loginLogo"
            >
            <QrCode @loginSuccess="onLoginSuccess" />
            <img
              class="tip"
              :src="Images.loginText"
            >
          </div>
          <div class="right">
            <div class="title">验证码登录</div>
            <LoginInput @loginSuccess="onLoginSuccess" />
            <div class="agreements">
              <Tooltip
                v-model:visible="loginStore.showTooltip"
                :target="agreeEl?.$el"
                content="请阅读并勾选隐私协议"
              >
                <reds-icon
                  ref="agreeEl"
                  size="14"
                  :icon="loginStore.agreed ? 'agreed' :'agree'"
                  class="radio"
                  @click="loginStore.toggleAgree()"
                />
              </Tooltip>
              <label @click="loginStore.toggleAgree()">
                我已阅读并同意</label><a
                target="_blank"
                href="https://www.xiaohongshu.com/terms"
              >《用户协议》</a><a
                target="_blank"
                href="https://www.xiaohongshu.com/privacy"
              >《隐私政策》</a><a
                target="_blank"
                href="https://oa.xiaohongshu.com/h5/terms/ZXXY20220516001/-1"
              >《儿童/青少年个人信息保护规则》</a>
            </div>
          </div>
        </div>
      </reds-modal>
    </transition>
  </div>
</template>

<script setup lang="ts">
  import {
    onMounted, onUnmounted, ref, watch,
  } from 'vue'
  import { useRouter } from 'vue-router'

  import RedsIcon from '@xhs/reds-icon-web'
  import RedsModal from '@xhs/reds-modal-web'
  import { eaglet } from '@xhs/launcher-plugin-eaglet'
  import { tracker } from '@xhs/protobuf-sns-pc-web-tracker'
  import { h5Toast } from '@xhs/reds-toast-web'

  import { Images } from '~/consts/images'
  import Tooltip from '~/components/primitive/Tooltip.vue'
  import QrCode from './QrCode.vue'
  import LoginInput from './Input.vue'
  import { useLoginStore } from '~/store/login'
  import { useUserStore } from '~/store/user'

  import '~/assets/close.svg'
  import '~/assets/clear.svg'
  import '~/assets/agree.svg'
  import '~/assets/agreed.svg'

  const userStore = useUserStore()
  const loginStore = useLoginStore()

  const router = useRouter()
  const agreeEl = ref()

  const onClose = () => {
    loginStore.toggleShowLogin(false)
    const path = router.currentRoute.value.path.replace('/login', '') || '/'
    loginStore.reset()
    const query = router.currentRoute.value.query
    delete query.from
    router.replace({ path, query })
  }

  const onEcsUp = (evt: KeyboardEvent) => {
    if (evt.key.toLowerCase() === 'escape') {
      onClose()
    }
  }

  watch(() => userStore.loggedIn, val => {
    if (val) {
      onClose()
    }
  })

  // 扫码或验证码登录成功，登录态变更后关闭弹窗
  const onLoginSuccess = async (from: string) => {
    eaglet.push(tracker[24450]({ channelType: from }))
    try {
      await userStore.getUserInfo()
      h5Toast('登录成功')
    } catch (error: any) {
      h5Toast(error.message || '薯队长遇到了点小麻烦')
    }
  }

  onUnmounted(() => {
    document.removeEventListener('keyup', onEcsUp)
  })

  onMounted(() => {
    loginStore.toggleShowLogin(true)
    document.addEventListener('keyup', onEcsUp)
    // 直接上报会导致 page_instance 取值错误，因此 setTimeout
    setTimeout(() => {
      eaglet.push(
        tracker[24452]({
          channelType: router.currentRoute.value.query.from || router.currentRoute.value.path,
        }),
      )
    })
  })

</script>

<style lang="stylus" scoped>

.reds-modal
  z-index 100

.fade-enter-active, .fade-leave-active
  transition all .2s

.fade-enter-from, .fade-leave-to
  opacity 0
  .login-container
    transform translateY(5px)

.login-container
  display flex
  position relative
  width 804px
  height 478px
  background #fff
  border-radius 16px
  padding 60px 0px
  transition all .2s

  .close-button
    position absolute
    right 20px
    top 20px
    cursor pointer

.left
  width 404px
  flex-center()
  flex-direction column
  border-right .5px solid rgba(0, 0, 0, 0.08)

  .logo
    width 148px
    height 78px
    user-select none

  .tip
    width 284px
    height 52px
    user-select none

.right
  width 400px
  flex-center()
  flex-direction column

  .title
    font-size 22px
    color #333
    font-weight 600

  .agreements
    width 280px
    position relative
    margin-top 16px
    padding-left 18px
    font-size 12px
    color rgba(51, 51, 51, 0.6)
    line-height 160%
    .radio
      position absolute
      left 0px
      top 2.5px
      cursor pointer

    a
      color rgb(14, 55, 101)

</style>
