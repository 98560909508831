<template>
  <div class="feeds-page">
    <div
      v-if="pageStatus === 'error'"
      class="error"
    >
      <section>
        <img :src="Images.Error">
        <div class="message">未连接到服务器，刷新一下试试</div>
        <div
          class="button"
          @click="refresh"
        >点击刷新</div>
      </section>
    </div>
    <Feeds v-if="pageStatus === 'feeds'" />
    <Skeleton v-if="pageStatus === 'loading'" />
  </div>
</template>

<script setup lang="ts">
  import { onBeforeMount, computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { useUserStore } from '~/store/user'
  import { useFeedStore } from '~/store/feed'
  import useGoLogin, { LoginFrom } from '~/composables/useGoLogin'
  import Feeds from '~/components/Feeds/Index.vue'
  import Skeleton from '~/components/Skeleton.vue'
  import { Images } from '~/consts/images'

  const userStore = useUserStore()
  const feedStore = useFeedStore()
  const route = useRoute()
  const goLogin = useGoLogin()

  const openLogin = async () => {
    await userStore.getUserInfo()
    if (!userStore.loggedIn && route.path !== '/explore/login') {
      goLogin(LoginFrom.FirstEnter)
    }
  }

  const pageStatus = computed(() => {
    if (feedStore.feeds.length > 0) {
      return 'feeds'
    }

    if (!feedStore.isFetching && feedStore.isError) {
      return 'error'
    }

    return 'loading'
  })

  onBeforeMount(async () => {
    await openLogin()
    if (feedStore.feeds.length <= 0) {
      feedStore.fetchFeeds()
    }
  })

  const refresh = async () => {
    await feedStore.fetchFeeds()
  }

</script>

<style scoped lang="stylus">
.feeds-page
  display flex
  flex-direction column
  align-items center
  position relative
  padding-top 88px
  min-height 100vh
  width 100vw
  min-width 960px
  max-width 100%
  background #fff

.error
  flex-center()
  width 100vw
  max-width 100%
  height calc(100vh - 88px)
  background #fff
  section
    display flex
    flex-direction column
    align-items center
  img
    width 160px
  .message
    margin-top 40px
    font-size 14px
    line-height 18px
    color rgba(51, 51, 51, 0.6)
  .button
    flex-center()
    margin-top 16px
    width  96px
    height  36px
    border  1px solid #FF2442
    border-radius  20px
    font-weight 500
    font-size 14px
    color #FF2442
    cursor pointer

</style>
