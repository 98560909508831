<template>
  <div
    class="avatar"
    :class="{'logged-in': userStore.loggedIn}"
  >
    <img
      class="avatar-img"
      :src="userStore.loggedIn ? formatUserImage() : Images.defaultAvatar"
      @click="toLogin"
    >
    <img
      v-if="!userStore.loggedIn"
      class="login-tip"
      :src="Images.unLogin"
    >
    <div class="user-menu-wrapper">
      <div class="user-menu">
        <div
          class="menu-item"
          @click="onLogout"
        >退出登录</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useRoute } from 'vue-router'
  import { getApiSnsWebV1LoginLogout } from '@xhs/logan-services-pc-web'
  import { Images } from '~/consts/images'
  import useGoLogin, { LoginFrom } from '~/composables/useGoLogin'
  import { useUserStore } from '~/store/user'
  import { useGlobalStore } from '~/store/global'

  const route = useRoute()
  const userStore = useUserStore()
  const globalStore = useGlobalStore()
  const goLogin = useGoLogin()

  const formatUserImage = () => {
    const url = userStore.userInfo.images
    if (globalStore.supportWebp) {
      return url
    }
    return url?.replace('webp', 'jpg')
  }

  const toLogin = () => {
    if (!route.path.includes('/login') && !userStore.loggedIn) {
      goLogin(LoginFrom.ClickLogin)
    } else if (userStore.userInfo.userId) {
      window.open(`https://www.xiaohongshu.com/user/profile/${userStore.userInfo.userId}`)
    }
  }

  const onLogout = async () => {
    await getApiSnsWebV1LoginLogout()
    window.location.reload()
  }

</script>

<style lang="stylus" scoped>

.logged-in:hover
  .user-menu-wrapper
    opacity  1
    visibility visible

.avatar
  position relative
  flex-center()
  margin-left 20px
  display flex
  width 36.5px
  height 36.5px
  border 0.5px solid #EBEBEB
  border-radius 100%

  .avatar-img
    width 36px
    height 36px
    cursor pointer
    border-radius 100%
  .login-tip
    position absolute
    left 16px
    top -8px
    width 42px

  .user-menu-wrapper
    transform translateY(100%)
    position absolute
    bottom 0px
    left -40px
    padding-top 16px
    opacity  0
    visibility hidden

  .user-menu
    display flex
    flex-direction column
    background $always_gray_0
    box-shadow 0px 10px 100px rgba(0, 0, 0, 0.06)
    border-radius 12px
    padding 6px
    word-break keep-all

  .menu-item
    padding 17px 24px
    transition background .2s
    cursor pointer
    &:hover
      background #F5F5F5
      border-radius 8px
    &:active
      background #eeeeee

</style>
