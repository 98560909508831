<template>
  <div>
    <div class="inner">
      <div class="left-nav">
        <h2 class="title">小红书社区规范</h2>
        <a
          v-for="(item, index) in list"
          :key="item.id"
          :href="`#item_${index}`"
          class="list"
        >{{ item.content }}</a>
      </div>
      <div class="right-content">
        <h2 class="title">小红书社区规范</h2>
        <p class="update-date">
          更新时间：2021年12月17日<br>
          生效时间：2021年12月24日
        </p>
        <div
          v-for="(rule, index) in rules"
          :key="rule.id"
          class="content"
        >
          <a :name="`item_${index - 1}`" />
          <h3
            v-if="rule.title"
            :ref="`title-${index}`"
            class="first-title"
          >{{ rule.num + rule.title }}</h3>
          <p
            v-if="rule.content"
            class="first-content"
            v-html="rule.content"
          />
          <div v-if="rule.terms">
            <div
              v-for="term in rule.terms"
              :key="term.id"
              class="terms-first"
            >
              <h4
                v-if="term.title"
                class="term-title"
              >{{ term.num + term.title }}</h4>
              <p
                v-if="term.content"
                class="term-content"
                v-html="term.content"
              />
              <ul
                v-if="term.bullets && typeof term.bullets[0] === 'string'"
                class="bullets"
              >
                <li
                  v-for="bullet in term.bullets"
                  :key="bullet.id"
                  class="bullet-li"
                  v-html="bullet"
                />
              </ul>
              <ul
                v-else-if="term.bullets"
                class="bullets"
              >
                <li
                  v-for="bullet in term.bullets"
                  :key="bullet.id"
                  class="bullet-li"
                  v-html="bullet.title + bullet.content"
                />
              </ul>
            </div>
          </div>
          <ul
            v-if="rule.bullets && typeof rule.bullets[0] === 'string'"
            class="bullets"
          >
            <li
              v-for="bullet in rule.bullets"
              :key="bullet.id"
              class="bullet-li"
              v-html="bullet"
            />
          </ul>
          <ul
            v-else-if="rule.bullets"
            class="bullets"
          >
            <li
              v-for="bullet in rule.bullets"
              :key="bullet.id"
              class="bullet-li"
              v-html="bullet.title + bullet.content"
            />
          </ul>
          <p
            v-if="rule.sufContent"
            class="first-suf-content"
            v-html="rule.sufContent"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { COMMUNITY_RULE } from './meta'

  export default {
    name: 'Main',
    data() {
      return {
        rules: COMMUNITY_RULE,
        list: [
          {
            content: '一、价值观',
          },
          {
            content: '二、法律法规',
          },
          {
            content: '三、交易及导流行为',
          },
          {
            content: '四、不当行为',
          },
          {
            content: '五、侵权行为',
          },
          {
            content: '六、社区处罚管理规范',
          },
          {
            content: '七、友情链接',
          },
        ],
      }
    },
    methods: {
      jump(index) {
        const ele = this.$refs[`title-${index}`]

        window.scrollTo(0, ele[0].offsetTop - 40)
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .inner
    width 960px
    margin 150px auto 0 auto
    overflow hidden
    position relative
  .left-nav
    width 230px
    float left
    background #FFFFFF
    position fixed
    top 150px
    z-index 1
    .title
      font-size 16px
      color #000000
      font-weight 600
      margin 15px
    .list
      color #555
      padding 11px 15px 10px
      margin 0
      display block
      border-left 2px solid #FFFFFF
      &:hover
        color #FF0036
        border-left 2px solid #FF0036
  .right-content
    width 710px
    padding 0 20px 20px 20px
    float left
    margin-left 250px
    background #FFFFFF
    margin-bottom 50px
    > .title
      font-size 20px
      text-align center
      margin 30px 0 12px 0
      border-bottom 1px dashed #EEE
      padding-bottom 30px
    .update-date
      margin 12px 0 40px
      font-size 12px
  .content
    margin 0
  .first-title
    margin 36px 0 0 0
  .term-title
    margin 18px 0 0 0
  .first-content
    margin 12px 0 0 0
  ul
    list-style none
    margin 18px 0 0 0
    li
      margin 0 0 18px 0
      line-height 24px
      &:before
        content "\2022"
        color #ff2442
        font-weight bold
        display inline-block
        width 1em
        margin-left -1em
</style>
