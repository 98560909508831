<template>
  <div
    class="footer"
    :class="[ isHome ? 'dark-theme' : 'light-theme',
              {white: ['ThirdStore', 'About'].includes($route.name) },
              {transparent: $route.name === 'Home'},
    ]"
  >
    <div class="top">
      <div class="navigation-bar-wrapper">
        <ul
          class="navigation-bar"
          :class="[showList.four.includes($route.name) ? 'four' : 'three', currentLang !== '中文(简体)' ? 'english' : 'chinese']"
        >
          <li
            class="navigation-item sign-up"
            @click="gotoPage('Terms')"
          >{{ policy.registrationAgreement }}</li>
          <li
            class="navigation-item privacy"
            @click="gotoPage('Privacy')"
          >{{ policy.privacyPolicy }}</li>
          <li
            class="navigation-item complaint"
            @click="gotoPage('ContentDisputeView')"
          >{{ policy.contentDispute }}</li>
          <li
            v-if="showList.ratingTerms.includes($route.name)"
            class="navigation-item remark"
            @click="gotoPage('RatingTerms')"
          >{{ policy.ratingTerms }}</li>
          <li
            v-if="$route.path.startsWith('/login') || showList.communityRule.includes($route.name)"
            class="navigation-item community"
            @click="gotoPage('CommunityRule')"
          >{{ policy.communityRule }}</li>
        </ul>
      </div>
    </div>
    <div class="bottom">
      <div class="info">
        <span class="line">
          <a
            target="_blank"
            href="//beian.miit.gov.cn"
            title="小红书_沪ICP备"
          >沪ICP备 13030189号 Copyright © 2014-{{ new Date().getFullYear() }} 行吟信息科技（上海）有限公司</a>
          <span>| 地址：上海市黄浦区马当路388号C座</span>
          <span>| 电话：021-64224530</span>
          <span>| <a
            target="_blank"
            href="https://fe-video-qc.xhscdn.com/fe-platform/1315dcf7990709473005051f944bafcc641a879b.pdf"
            title="小红书_营业执照"
          >营业执照</a>
          </span>
          <span>| <a
            target="_blank"
            href="//dc.xhscdn.com/06c2adb0-b353-11e9-9d0c-7be9ff8961c1/自营经营者信息公示.pdf"
          >自营经营者信息</a></span>
          <span>| <a
            target="_blank"
            href="//www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102002533"
            title="小红书_沪公网安备"
          >{{ new Date().getFullYear() }}  沪公网安备 31010102002533号<i class="police" /></a></span>
        </span>
        <span class="line">
          <a
            target="_blank"
            href="//fe-video-qc.xhscdn.com/fe-platfrom/d0b7aeed1d933b011c2f75d0330714f17b046473.pdf"
            title="小红书_网络文化经营许可"
          > 网络文化经营许可证：沪网文［2021］1545-113号</a>
          | <a
            target="_blank"
            href="//fe-video-qc.xhscdn.com/picasso-editor/c081c205c927d605c959e3d6e1b22975c8a4b64f.pdf"
            title="小红书_网文"
          >增值电信业务经营许可证：沪B2-20150021</a>
        </span>
        <span class="line">
          <a
            target="_blank"
            href="https://fe-video-qc.xhscdn.com/fe-platform/51952e9ac05602a79919e093fb3db442592296f9.pdf"
            title="小红书_医疗器械经营"
          >医疗器械经营许可证：沪嘉食药监械经营许20187006号</a>
          | <a
            target="_blank"
            href="https://fe-video-qc.xhscdn.com/fe-platform/901dbbfc0518eebcd191c8273ea9d219bd8cc02b.pdf"
            title="小红书_医疗器械网络交易服务"
          >医疗器械网络交易服务第三方平台备案:（沪）网械平台备字[2019]第00006号</a>
          | <a
            target="_blank"
            href="https://fe-video-qc.xhscdn.com/fe-platform/d265bddd6bda054b400903a45e01889d35dd118e.pdf"
            title="小红书_互联网药品信息服务"
          >互联网药品信息服务资格证书：(沪)-经营性-2018-0011</a>
        </span>
        <span class="line">
          <span title="小红书_违法不良信息">违法不良信息举报电话：(027) 8700 3878</span>
          | <a
            target="_blank"
            href="http://www.shjbzx.cn"
            title="小红书_上海市互联网举报中心"
          >上海市互联网举报中心</a>
          | <a
            target="_blank"
            href="http://www.12377.cn"
            title="网上有害信息举报专区"
          >网上有害信息举报专区</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  const addLogin = routeName => routeName.map(it => [it, `${it}Login`]).flat()

  export default {
    name: 'Footer',
    props: {
      isHome: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        currentLanguage: '中文(简体)',
        corpus: {
          'en-US': 'English(US)',
          'zh-CN': '中文(简体)',
        },
        showPopOver: false,
        timer: {},
        showList: {
          four: addLogin(['Explore', 'Home', 'CommunityRule', 'NoteView', 'User']),
          communityRule: addLogin(['Explore', 'Home', 'CommunityRule', 'NoteView', 'User']),
          ratingTerms: addLogin(['RatingTerms']),
        },
      }
    },
    computed: {
      currentLang: {
        get() {
          return '中文(简体)'
        },
        set(newValue) {
          this.currentLanguage = newValue
        },
      },
      policy() {
        return {
          registrationAgreement: '用户协议',
          privacyPolicy: '隐私政策',
          contentDispute: '侵权投诉指引',
          ratingTerms: '小红书评价规则',
          communityRule: '社区规范',
        }
      },
    },
    methods: {
      gotoPage(content) {
        this.$router.push({ name: content })
      },
    },
  }
</script>

<style lang="stylus" scoped>
  a
    text-decoration none
  .dark-theme
    .top
      color #fff
      .translation-wrapper
        color #fff
    .bottom
      .info
        color #999
        .line
          a
            color #999
  .light-theme
    background-color #fbfbfb
    .top
      color #333
      .translation-wrapper
        color #333
      .navigation-item
        &:hover
          color #fe2543
    .bottom
      .info
        color #999
        .line
          a
            color #999

  .footer
    position relative
    font-family "PingFang SC", Helvetica, Arial, sans-serif
    padding-top 50px
    &.white
      background-color #fff
    &.transparent
      background-color transparent
  .top
    font-size 14px
    font-weight 500
    text-align center
    .navigation-bar-wrapper
      width 100%
      display flex
      flex-direction row
      justify-content center
      align-items center
      margin 0 0 20px 0
    .navigation-bar
      width 1080px
      margin 0
      padding 0 80px 0 60px
      display flex
      flex-direction row
      justify-content space-between
      align-items center
      list-style-type none
      position relative
      &.four
        padding 0 60px 0 45px
      .navigation-item
        cursor pointer
      .translation-wrapper
        position relative
        display inline-block
        text-decoration none
        &:hover
          .drop-up-wrapper
            display block
          .translation
            .arrow
              transform rotate(0deg)
        .translation
          display flex
          flex-direction row
          justify-content flex-start
          align-items center
          .arrow
            margin-left 4px
            transform rotate(90deg)
            transition transform 0.3s
            width 10px
            height 6px
        .drop-up-wrapper
          position absolute
          display none
          left -30px
          bottom 12px
          width 135px
          .drop-up-content
            margin-bottom 30px
            background-color #ffffff
            z-index 1001
            text-align center
            overflow hidden
            border-radius 10px
            span
              margin-right -1px
              display block
              white-space nowrap
              color #999
              font-weight 300
              text-decoration none
              font-size 16px
              line-height 16px
              padding 15px 30px
              cursor pointer
              &:hover
                background-color #f5f5f5

  .bottom
    width 100%
    height 70px
    .info
      display flex
      flex-direction column
      justify-content space-between
      align-items center
      width 100%
      height 70px
      font-size 12px
      line-height 18px
      font-weight normal
      .line
        display flex
        flex-direction row
        justify-content center
        align-items center
        white-space nowrap
        i
          display inline-block
          vertical-align middle
          margin-top -3px
          margin-left 5px
          width 18px
          height 18px
          background-repeat no-repeat
          background-size contain
          cursor pointer
        .police
          background-image url("//ci.xiaohongshu.com/b0b2023e-bf3c-4994-9b67-cb8758739684")
        .email
          background-image url('//ci.xiaohongshu.com/17466315-4279-4464-b12d-aa4a5c6342c0')
</style>
