<template>
  <div class="outline">
    <div
      v-for="(item, index) in slicedList"
      :key="index"
      class="item-wrapper"
      @click="viewItem(item)"
    >
      <div class="image-wrapper">
        <img
          class="image"
          :src="item.imgSrc || '//ci.xiaohongshu.com/1e6001ad-45fc-49f5-866d-0dbaae1b5ecd@r_320w_320h.jpg'"
        >
      </div>
      <div class="item">
        <div class="title-wrapper">
          <h3 class="title"><span
            v-if="item.tag"
            class="tag"
          >【{{ item.tag }}】</span>{{ item.title }}</h3>
        </div>
        <span class="content">{{ item.content[0].paragraph || item.content[1].paragraph || item.content[2].paragraph }}</span>
        <span class="date">{{ item.date }}</span>
      </div>
    </div>
    <div class="pagination">
      <span
        class="prev page"
        :class="{ disabled: currentIndex === 0 }"
        @click="prevPage"
      >上一页</span>
      <span
        v-for="index in totalPage"
        :key="index"
        class="pages page"
        :class="{ current: index === currentIndex + 1 }"
        @click="changeCurrentPage(index)"
      >{{ index }}</span>
      <span
        class="next page"
        :class="{ disabled: currentIndex === totalPage - 1 }"
        @click="nextPage"
      >下一页</span>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'LiabilityList',
    components: {
    },
    props: {
      list: {
        type: Array,
        default: () => ([]),
      },
    },
    data() {
      return {
        currentIndex: 0,
        splitNum: 5,
      }
    },
    computed: {
      totalPage() {
        const arrayLength = this.list.length

        if (arrayLength !== 0) {
          return Math.ceil(arrayLength / this.splitNum)
        }
        return 0
      },
      slicedList() {
        return this.list.slice(this.currentIndex * this.splitNum, this.currentIndex * this.splitNum + 5)
      },
    },
    watch: {
      currentIndex() {
        // smoothScroll(window, 486, 300)
        window.scrollTo(0, 300)
      },
    },
    methods: {
      viewItem(item) {
        const { href } = this.$router.resolve({
          name: 'LiabilityItem',
          query: {
            id: item.id,
          },
        })

        window.open(href, '_self')
      },
      prevPage() {
        if (this.currentIndex > 0) this.currentIndex -= 1
      },
      nextPage() {
        if (this.currentIndex < this.totalPage - 1) this.currentIndex += 1
      },
      changeCurrentPage(index) {
        this.currentIndex = index - 1
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .outline
    display flex
    flex-direction column
    justify-content flex-start
    align-items center
    padding-top 80px
  .item-wrapper
    width 940px
    height 198px
    display flex
    flex-direction row
    justify-content flex-start
    align-items center
    background-color #fff
    margin 0 0 58px 0
    cursor pointer
    .image-wrapper
      height 100%
      .image
        width 310px
        height 100%
        object-fit cover
    .item
      margin 0 0 0 36px
      padding-right 30px
      height 189px
      display flex
      flex-direction column
      justify-content space-around
      align-items flex-start
      .title-wrapper
        margin 10px 0 0 0
        font-size 20px
        color #000
        .title
          font-size 24px
          line-height 28px
          margin 0
          .tag
            font-size 20px
            line-height 28px
            margin-left -12px
      .content
        color #666
        font-size 14px
        line-height 24px
        width 581px
        height 48px
        text-overflow ellipsis
        overflow hidden
      .date
        color #999
  .pagination
    display flex
    flex-direction row
    justify-content flex-end
    align-items center
    width 940px
    margin 12px 0 0 0
    .page
      display inline-block
      height 40px
      margin-left 15px
      background-color #fff
      border 1px solid #eee
      border-radius 4px
      font-size 14px
      font-weight 400
      line-height 40px
      text-align center
      color #000
      cursor pointer
      &:hover
        border-color #ff2741
        color #ff2741
      &.disabled
        color #999
        &:hover
          border-color #eee
    .prev
      width 76px
    .pages
      width 40px
      &.current
        background-color #FF2741
        color #fff
        border-color #ff2741
    .next
      width 76px
</style>
