<template>
  <div class="qrcode">
    <template v-if="loginStore.qrData.image">
      <img
        class="qrcode-img"
        :src="loginStore.qrData.image"
      >
      <img
        class="icon"
        :src="Images.loginIcon"
      >
      <div
        v-show="loginStore.qrData.status !== QrCodeStatus.Valid"
        class="status"
      >
        <template v-if="loginStore.qrData.status === QrCodeStatus.OutDated">
          <div
            class="outdated"
            @click="onRefresh"
          >
            <reds-icon
              size="20"
              icon="refresh"
            />刷新重试</div>
          <div class="description">二维码已失效</div>
        </template>
        <template v-if="loginStore.qrData.status === QrCodeStatus.Scanned">
          <div class="">
            <reds-icon
              size="20"
              icon="agreed"
            />扫码成功</div>
          <div class="description">请在手机端确认登录</div>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
  import { onBeforeMount, onBeforeUnmount, ref } from 'vue'
  import QRCode from 'qrcode'
  import RedsIcon from '@xhs/reds-icon-web'
  import { eaglet } from '@xhs/launcher-plugin-eaglet'
  import { tracker } from '@xhs/protobuf-sns-pc-web-tracker'
  import {
    postApiSnsWebV1LoginQrcodeCreate,
    getApiSnsWebV1LoginQrcodeStatus,
  } from '@xhs/logan-services-pc-web'
  import { h5Toast } from '@xhs/reds-toast-web'
  import { Images } from '~/consts/images'
  import { useLoginStore, QrCodeStatus } from '~/store/login'
  import { useUserStore } from '~/store/user'

  import '~/assets/agreed.svg'
  import '~/assets/refresh.svg'

  /**
   * 什么时候要对 400 做单独判断：
   * 接口 catch 以后要进行进一步操作
   * 比如查询二维码状态失败后，需要继续查询。
   * 但是 400 的场景就不需要继续查询了，而是弹窗提示
   */

  const loginStore = useLoginStore()
  const userStore = useUserStore()

  const emit = defineEmits<{(e: 'loginSuccess', from: 'qr_code'): void}>()
  const timer = ref<ReturnType<typeof setTimeout>>()

  function getQrCodeStatusWithDelay(fn:any, delay: number) {
    clearTimeout(timer.value)
    timer.value = setTimeout(fn, delay)
  }

  const qrCodeExpose = () => {
    eaglet.push(tracker[24448]({ status: loginStore.qrData.status }))
  }

  // 轮询二维码状态
  const getQrCodeStatus = async () => {
    if (!loginStore.showLogin) return
    if (userStore.loggedIn) return
    try {
      const { codeStatus } = await getApiSnsWebV1LoginQrcodeStatus({
        params: loginStore.qrData.backend,
      })
      switch (codeStatus) {
        case 2:
          emit('loginSuccess', 'qr_code')
          break
        case 3:
          loginStore.qrData.status = QrCodeStatus.OutDated
          qrCodeExpose()
          break
        case 1:
          loginStore.qrData.status = QrCodeStatus.Scanned
          qrCodeExpose()
          getQrCodeStatusWithDelay(getQrCodeStatus, 1000)
          break
        default:
          loginStore.qrData.status = QrCodeStatus.Valid
          getQrCodeStatusWithDelay(getQrCodeStatus, 1000)
          break
      }
    } catch (error: any) {
      // -13002 为风控拦截
      if (error.code === -13002) {
        h5Toast(error.message || '薯队长遇到了点小麻烦')
      } else if (error.code === 400) {
        h5Toast(error.message || '薯队长遇到了点小麻烦')
      } else {
        getQrCodeStatusWithDelay(getQrCodeStatus, 1000)
      }
      throw error
    }
  }

  // 获取扫码登录链接，创建二维码图片，然后开始轮询二维码状态
  const drawQrCode = async () => {
    try {
      const { qrId, code, url } = await postApiSnsWebV1LoginQrcodeCreate({})
      loginStore.updateQrData(qrId, code)
      loginStore.qrData.image = await QRCode.toDataURL(url as string, {
        margin: 0,
        width: 155,
      })
      setTimeout(qrCodeExpose)
      getQrCodeStatusWithDelay(getQrCodeStatus, 1000)
    } catch (error: any) {
      h5Toast(error.message)
      throw error
    }
  }

  const onRefresh = () => {
    loginStore.updateQrData('', '')
    loginStore.qrData.image = ''
    loginStore.qrData.status = QrCodeStatus.Valid
    drawQrCode()
  }

  onBeforeMount(drawQrCode)
  onBeforeUnmount(() => {
    clearTimeout(timer.value)
  })

</script>

<style lang="stylus" scoped>
.qrcode
  position relative
  flex-center()
  margin 32px 0
  width 164px
  height 164px
  font-size 14px
  line-height 18px
  font-weight 500
  .icon
    position absolute
    left 66.5px
    top 66.5px
    width 31px
    height 31px

  .qrcode-img
    width 155px
    height 155px

  svg
    margin-right 8px
  .status
    position absolute
    left 0
    top 0
    width 100%
    height 100%
    flex-column-center()
    background rgba(255, 255, 255, 0.95)
    backdrop-filter blur(2.5px)

    div:first-child
      flex-center()
  .outdated
    color $light_red_400
    cursor pointer
  .description
    margin-top 6px
    font-size 12px
    line-height 16px
    color rgba(51, 51, 51, 0.8)
    font-weight 400
</style>
