<template>
  <div>
    <div class="qrcode-group">
      <div
        v-for="(qrcode, index) in qrcodes"
        :key="index"
        class="qrcode"
        :class="{hoverable: qrcode.webSite}"
        :src="qrcode.src"
        @click="gotoWebsite(qrcode)"
      >
        <img
          class="image"
          :src="qrcode.src"
        >
        <span class="description">{{ qrcode.description }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Qrcodes',
    props: {
      qrcodes: {
        type: Array,
        default: () => ([]),
      },
    },
    methods: {
      gotoWebsite(qrcode) {
        if (qrcode.webSite) {
          window.open(qrcode.webSite, '_blank')
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .qrcode-group
    display flex
    flex-direction row
    justify-content center
    align-items center
    .qrcode
      width 180px
      height 180px
      margin-right 10px
      display flex
      flex-direction column
      justify-content flex-start
      align-items center
      background-color #fff
      &.hoverable
        cursor pointer
      .image
        margin-top 31px
        width 90px
        height 90px
      .description
        margin-top 20px
        color #555
        font-size 16px
        line-height 18px
        font-weight 400
</style>
