<template>
  <div class="business-page">
    <div class="container">
      <div class="brief-cards-wrapper">
        <h2 class="title">为什么选择小红书</h2>
        <p class="subtitle">
          年轻人热爱的生活分享平台
          <a
            class="subtitle-link"
            target="_blank"
            href="//dc.xhscdn.com/2116176ae2f32d41242abb9823cc0743d0593288.pdf"
            title="小红书_广告产品介绍"
          >下载广告产品介绍</a>
        </p>
        <brief-cards :brand-cards="brandCards" />
      </div>
      <div class="process-cards-wrapper">
        <h2 class="title">广告投放申请流程</h2>
        <p class="subtitle">提交资质申请，通过后开启广告投放</p>
        <process-cards :process-cards="processCards" />
      </div>
      <div class="inner-issue">
        <h2>常见问题</h2>
        <brand-issue :group-issues="normalIssue" />
      </div>
    </div>
  </div>
</template>
<script>
  import BriefCards from './components/BriefCards.vue'
  import ProcessCards from './components/ProcessCards.vue'
  import BrandIssue from './components/BrandIssue.vue'

  export default {
    name: 'EconomyEdition',
    components: {
      BriefCards,
      ProcessCards,
      BrandIssue,
    },
    data() {
      return {
        normalIssue: [],
        domesticIssue: '',
        abroadIssue: '',
        middleOne: true,
        middleTwo: false,
        brandCards: [],
        processCards: [],
      }
    },
    created() {
      this.assignBrandCards()
      this.assignProcessCards()
      this.assignNormalIssue()
      this.assignDomesticIssue()
      this.assignAbroadIssue()
    },
    methods: {
      middleOnetype() {
        this.middleOne = true
        this.middleTwo = false
      },
      middleTwotype() {
        this.middleOne = false
        this.middleTwo = true
      },
      assignBrandCards() {
        this.brandCards = [
          {
            imgSrc: '//ci.xiaohongshu.com/3580a105-238d-407a-a9b7-dd5f667c6d50',
            title: '一站式',
            topContent: '场景化解决方案',
            bottomContent: '覆盖多种营销诉求',
          },
          {
            imgSrc: '//ci.xiaohongshu.com/a7d9172d-f3b1-460a-a760-db45ffafd908',
            title: '双引擎',
            topContent: '搜索&浏览场景打通',
            bottomContent: '深度影响消费决策',
          },
          {
            imgSrc: '//ci.xiaohongshu.com/2f887402-1eb3-4ebd-afc0-7ab75f789c98',
            title: '智能化',
            topContent: '智能投放能力',
            bottomContent: '科学提升营销效率',
          },
          {
            imgSrc: '//ci.xiaohongshu.com/4ca1b106-5ff2-42f9-bf1a-d3477532edce',
            title: '高效率',
            topContent: '全链路数据支持',
            bottomContent: '助力商家营销决策',
          },
        ]
      },
      assignProcessCards() {
        this.processCards = [
          {
            title: '账号申请',
            topContent: '使用小红书App账号',
            prefix: '登录',
            bottomContent: '聚光平台',
            link: '//ad.xiaohongshu.com/',
          },
          {
            title: '资质认证',
            topContent: '根据提示要求，提交认证材料',
            bottomContent: '聚光平台开户流程',
            link: '//fe-video-qc.xhscdn.com/fe-platform/cc8a5f81ddb6d91d6be9dfb34e62b49b999f234b/%E8%81%9A%E5%85%89%E5%B9%B3%E5%8F%B0%E6%B3%A8%E5%86%8C%E4%B8%8E%E5%BC%80%E6%88%B7%E6%B5%81%E7%A8%8B.pdf',
          },
          {
            title: '开启投放',
            topContent: '开启广告投放',
            bottomContent: '提升生意效率',
          },
          {
            title: '关注平台',
            topContent: '打开小红书，搜索并关注',
            bottomContent: '「聚光小助手」',
            suffix: '学习更多',
            link: '//www.xiaohongshu.com/user/profile/62470e0800000000210250b3',
          },
        ]
      },
      async assignNormalIssue() {
        this.normalIssue = [
          {
            groupName: '代理商名单',
            groupContent: [
              {
                content: '请登录小红书合作伙伴平台（https://partner.xiaohongshu.com/login）点击「申请入驻」，选择「效果代理」作为代理商类型并提交相关资料。具体操作可见下面的操作手册。',
                question: '如何成为小红书的竞价类代理商',
              },
              {
                html: '<a target="_blank" href="https://fe-video-qc.xhscdn.com/fe-platform/1e2517b8f177541699ad87d0b08e9d8174a3e6d4.pdf">竞价类代理商入驻操作手册</a>',
                style: 'margin-top: -10px',
              },
            ],
          },
          {
            groupName: '开户流程',
            groupContent: [
              {
                content: '开通广告投放账户有三个环节：<br/> ①在小红书APP注册小红书账号；<br/> ②登录聚光平台根据平台引导完成专业号认证 (https://business.xiaohongshu.com/)；<br/> ③在聚光平台【广告-账号管理-推广资质认证】中完成推广资质认证',
                question: '如何开通广告投放账户？',
              },
              {
                content: '认证环节一般会在1个工作日内反馈审核结果，请及时查询认证状态。',
                question: '申请后多久会通过认证呢？',
              },
              {
                question: '能否同时进行专业号认证和推广资质认证？',
                content: '不可以，专业号认证成功，是推广资质认证的前提。',
              },
            ],
          },
          {
            groupName: '直签与代理商',
            groupContent: [
              {
                content: '直签需要品牌方自己预充值、管理账户并制作素材。通过代理商合作代理商会管理账户，提供素材制作、广告计划搭建、运营优化等服务。是否提供账户可以和代理商协商沟通，平台不做强制要求。',
                question: '直签和代理商的区别是什么？通过代理商合作，代理商是否会提供账户给品牌？',
              },
              {
                content: '可切换。直签可切换为代理商签约，代理商签约可切换为直签；原通路已充值费用不可转移，即直签账户余额不可转移至代理商开通的虚拟子账户，反之亦然。',
                question: '直签和代理商签约是否可切换？',
              },
            ],
          },
          {
            groupName: '财务',
            groupContent: [
              {
                content: '新开账户起充金额为 2 千元。',
                question: '起充门槛是多少？',
              },
              {
                content: '小红书支持线下汇款和支付宝充值两种方式。',
                question: '如何充值？',
              },
              {
                question: '如何操作线下汇款？',
                content: '小红书为您提供效果广告线下汇款专属账户， 切勿充错业务，系统在收到银行汇款后将自动匹配到您的效果广告账户，并为您充值，付款账户名称务必与注册我司的公司名称保持一致，否则将导致充值无法到账。',
              },
              {
                question: '境外商家如何打款？',
                content: '仅支持境内银行汇款， 不支持境外人民币及外币汇款 ，到账时间同行1-2天，跨行3-5天，请您耐心等待。',
              },
              {
                question: '充值遇到问题如何解决？',
                content: '充值遇到问题请发邮件至red.ads_fn@xiaohongshu.com ，请详细描述您的账号、汇款账号、汇款金额等信息，将有专人跟进。',
              },
            ],
          },
        ]
      },
      assignDomesticIssue() {
        this.domesticIssue = [
          {
            title: '基础信息',
            content: [
              {
                info: '账号名字',
              },
              {
                info: '品牌介绍',
              },
              {
                info: '行业类别',
              },
              {
                info: '所在地',
              },
            ],
          },
          {
            title: '头像/证明材料',
            content: [
              {
                info: '头像',
                item: [
                  { main: '头像必须为品牌形象，如品牌LOGO或企业商标' },
                ],
              },
              {
                info: '认证公函',
                item: [
                  {
                    example: true,
                    exampleurl: '//ci.xiaohongshu.com/42cf420c-70b0-4a1c-b438-57b03e12370c',
                    letter: true,
                    letterurl: 'https://o3.xiaohongshu.com/pdf/application.pdf',
                    mainone: '公函需下载打印填写，并加盖',
                    mainred: '品牌方',
                    maintwo: '的实体公章后拍摄上传，公函中的“小红书账号名字”需与商标注册证一致',
                  },
                ],
              },
              {
                info: '企业营业执照',
                item: [
                  {
                    example: true,
                    exampleurl: '//ci.xiaohongshu.com/dae20c4e-c3c5-473a-84ad-547f57f6ad32',
                    mainone: '上传',
                    mainred: '品牌方',
                    maintwo: '的中国大陆工商局或市场监督管理局的最新工商营业执照（三证合一）',
                  },
                ],
              },
              {
                info: '商标注册证',
                item: [
                  { main: '若品牌认证名称/信息包含品牌，需上传商标注册证' },
                ],
              },
              {
                info: '网站ICP备案截图',
                item: [
                  {
                    main: '若品牌账号认证内容为网站，需上传网站在工信部网站备案查询的截图，要求内容真实完整、清晰可识别，需加盖网站主办单位公章',
                  },
                ],
              },
              {
                info: '软件著作证',
                item: [
                  {
                    main: '若品牌账号认证内容为软件/APP，需提供软件著作权登记证书原件彩色扫描件',
                  },
                ],
              },
            ],
          },
          {
            title: '企业信息',
            content: [
              {
                info: '企业名称',
              },
              {
                info: '统一社会信用代码',
              },
              {
                info: '营业期限',
              },
              {
                info: '运营者姓名',
              },
              {
                info: '运营者手机号',
              },
            ],
          },
        ]
      },
      assignAbroadIssue() {
        this.abroadIssue = [
          {
            title: '基础信息',
            content: [
              {
                info: '账号名字',
              },
              {
                info: '品牌简介',
              },
              {
                info: '行业类别',
              },
              {
                info: '所在地',
              },
            ],
          },
          {
            title: '头像/证明材料',
            content: [
              {
                info: '头像',
                item: [
                  {
                    main: '头像必须为品牌形象，如品牌LOGO或企业商标',
                  },
                ],
              },
              {
                info: '认证公函',
                item: [
                  {
                    mainone: '公函需下载打印填写，并加盖',
                    mainred: '品牌方',
                    maintwo: '的实体公章后拍摄上传，公函中的“小红书账号名字”需与商标注册证一致 ',
                  },
                ],
              },
              {
                info: '公司注册文件',
                item: [
                  {
                    main: 'Business registration（简称“BR”）或Certificate of incorporation（简称“CR”）的原件彩色扫描件，若为香港公司，BR和CR均需上传。',
                  },
                ],
              },
              {
                info: '商标注册证',
                item: [
                  {
                    main: '若品牌认证名称/信息包含品牌，需上传商标注册证',
                  },
                ],
              },
            ],
          },
          {
            title: '企业信息',
            content: [
              {
                info: '企业名称',
              },
              {
                info: '运营者姓名',
              },
              {
                info: '运营者手机号',
              },
            ],
          },
        ]
      },
    },
  }
</script>
<style lang="stylus" scoped>
  .business-page
    .container
      .brief-cards-wrapper
        background #fff
        display flex
        flex-direction column
        justify-content flex-start
        align-items center
        padding-bottom 100px
        .title
          color #333
          font-size 25px
          margin-bottom 10px
        .subtitle
          color #999
          font-size 13px
          margin 0 0 48px 0
          .subtitle-link
            text-decoration none
            margin-left 16px
            color #5B92E1
            line-height 13px
            position relative
            cursor pointer
            &:after
              content ''
              position absolute
              bottom 0
              left 0
              width 100%
              height 1px
              background-color #5B92E1
      .process-cards-wrapper
        background #f7f7f7
        display flex
        flex-direction column
        justify-content flex-start
        align-items center
        padding-top 50px
        padding-bottom 100px
        .title
          color #333
          font-size 25px
          margin-bottom 10px
        .subtitle
          color #999
          font-size 13px
          margin 0 0 48px 0
      .inner-enter
        background #f7f7f7
        height 508px
        text-align center
        padding 60px 0 0 0
        h2
          font-size 25px
          font-weight 500
          color $BLACK
          margin 0 0 10px 0
        h5
          font-size 13px
          color #999
          font-weight normal
          margin 0 0 50px 0
        .inner-flow
          position relative
          width 1080px
          margin 0 auto
          .list
            width 320px
            height 240px
            text-align center
            background #fff
            color #2c2c2c
            font-size 20px
            font-weight 500
            float left
            margin 0 20px
            border-radius 8px
            box-shadow 0px 2px 20px 0px rgba(0,0,0,0.03)
            .title
              margin 25px 0 0 0
            .icon
              width 96px
              height 37px
              display block
              margin-top 15px
            .content
              font-size 16px
              line-height 1.5
              color #747474
              font-weight normal
              margin 18px 0 0 0
            .pra-one,.pra-two
              margin 5px 0
            .maomap
              color #5B92E1
              font-weight 500
              cursor pointer
      .inner-detail-wrapper
        width 100%
        background #fff
        .inner-detail
          margin 0 auto
          padding-bottom 55px
          width 1030px
          h4
            text-align center
            font-size 25px
            margin 0
            padding-top 55px
          .top-topic
            margin 50px 0 0 0
            overflow hidden
            .inner
              width 200px
              height 42px
              line-height 42px
              border-radius 31px
              border 1px solid #FF5267
              float left
              cursor pointer
              text-align center
              color #FF5267
              font-weight 500
              &:first-child
                margin 0 40px 0 295px
            .inner.select
              background #FF5267
              color #fff
          .middle-topic
            position relative
            .inner
              overflow hidden
              margin 60px 0 0 0
              h4
                float left
                width 150px
                font-size 20px
                font-weight 500
                line-height 1.25
                text-align left
                color #2c2c2c
                margin 0 40px 0 0
                padding 0
              ul
                float left
                margin 0
                width 680px
                font-size 18px
                line-height 1.6
                .main
                  &:first-child
                    .topadd
                      margin 0 0 8px -28px
                  .rightadd
                    margin 0 0 0 0
                  .topadd
                    margin 32px 0 8px -28px
                  .example,.letter
                    color #5B92E1
                    font-weight 500
                    margin-right 8px
                  .red
                    color #FF2741
                    font-weight 500
      .inner-issue
        margin 0 auto
        background-color #f7f7f7
        padding  0 0 60px 0
        h2
          font-size 25px
          line-height 1.09
          letter-spacing -0.6px
          text-align center
          font-weight 500
          padding 60px 0 0 0
          margin 0 0 50px 0
  @media screen and (max-width: 1250px) and (min-width: 1101px)
    .business-page
      .container
        margin-top 96px
  @media screen and (max-width: 1100px)
    .business-page
      .container
        margin-top 85px
</style>
