<template>
  <header :class="{ transparent, fixed }">
    <router-link to="/">
      <img
        width="60"
        :src="transparent ? Images.darkLogo : Images.lightLogo"
      >
    </router-link>
    <div class="right">
      <Nav />
      <User />
    </div>
  </header>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { Images } from '~/consts/images'

  import Nav from './Nav.vue'
  import User from './User.vue'

  const route = useRoute()
  const transparent = computed(() => route.meta.transparentHeader)
  const fixed = computed(() => route.path.startsWith('/explore'))

</script>

<style lang="stylus" scoped>
header
  position relative
  display flex
  padding 0 80px
  align-items center
  justify-content space-between
  width 100%
  min-width 960px
  height 72px
  z-index 10

  &.transparent
    position absolute
    left 0
    top 0

  &.fixed
    position fixed
    left 0
    top 0
    background rgba(255, 255, 255, 0.9)
    border-bottom 0.5px solid #FFFFFF
    backdrop-filter blur(25px)

.right
  display flex
</style>
