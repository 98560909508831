<template>
  <div class="passage-wrapper">
    <div class="passage">
      <h2 class="title">{{ passage.title }}</h2>
      <span class="subtitle">
        <span class="date">{{ releaseTime }}: {{ passage.date }}</span>
      </span>
      <div class="content-wrapper">
        <span
          v-for="(item, index) in passage.content"
          :key="index"
          class="content"
        >
          <span
            v-if="item.htmlPara"
            class="paragraph-html"
            v-html="item.htmlPara"
          />
          <span
            v-if="item.paragraph"
            class="paragraph"
          >{{ item.paragraph }}</span>
          <img
            v-else-if="item.img"
            class="inline-picture"
            :src="item.img"
          >
          <span
            v-else-if="item.src"
            class="clickable-url"
            @click="gotoSrc(item.src)"
          >{{ item.src }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { LIABILITY_LIST } from './meta'

  export default {
    name: 'Main',
    data() {
      return {
        item: {},
        passage: {},
      }
    },
    computed: {
      releaseTime() {
        return '发布时间'
      },
    },
    created() {
      this.passage = LIABILITY_LIST.find(item => item.id === this.$route.query.id)
    },
    methods: {
      gotoSrc(src) {
        if (src) {
          window.open(src, '_blank')
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .passage-wrapper
    background-color #fafafa
    margin-top 106px
    width 100%
    .passage
      width 940px
      padding 50px 100px
      margin 50px auto 50px auto
      border-radius 8px
      background-color #fff
      display flex
      flex-direction column
      justify-content flex-start
      align-items flex-start
      .title
        margin 0 0 0 0
        font-size 32px
        line-height 45px
        color #333
      .subtitle
        margin 42px 0 0 0
        display flex
        flex-direction row
        justify-content flex-start
        align-items center
        .date
          font-size 16px
          line-height 22px
          color #666
        .line
          margin 0 48px 0 36px
          display inline-block
          height 20px
          width 1px
          background-color #4d4d4f
        .viewSum
          font-size 16px
          line-height 22px
          color #666
      .content-wrapper
        margin 26px 0 0 0
        width 740px
        font-size 16px
        line-height 32px
        color #333
        .content
          width 740px
          display flex
          flex-direction column
          justify-content flex-start
          align-items stretch
          position relative
          .paragraph-html
          .paragraph
          .clickable-url
            margin 15px auto
            width 100%
            text-indent 32px
            text-align justify
          .paragraph-html
            text-indent 0px
            margin-left 32px
          .inline-picture
            width 70%
            margin 20px auto
          .clickable-url
            cursor pointer
            margin-top 0
            &:hover
              color #FF2442
</style>
