<template>
  <div class="submenu-wrapper">
    <div
      class="submenu"
    >
      <template
        v-for="(subMenu, subIndex) in menu.children"
        :key="subIndex"
      >
        <router-link
          v-if="subMenu.path"
          :to="subMenu.path"
          active-class=""
          class="submenu-item"
        >{{ subMenu.name }}</router-link>
        <a
          v-else
          :href="subMenu.href"
          target="_blank"
          class="submenu-item"
        >{{ subMenu.name }}</a>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { MenuItem } from '~/config/menu.config'

  defineProps<{
    menu: MenuItem
  }>()

</script>

<style lang="stylus" scoped>

.submenu-wrapper
  position absolute
  left 0px
  bottom 0px
  opacity 0
  visibility hidden
  transform translateY(95%)
  padding-top 16px
  font-weight 400
  transition opacity .2s, transform .2s

.submenu
  display flex
  flex-direction column
  background $always_gray_0
  box-shadow 0px 10px 100px rgba(0, 0, 0, 0.06)
  border-radius 12px
  padding 6px

  .submenu-item
    padding 17px 24px
    word-break keep-all
    transition background .2s
    &:hover
      background #F5F5F5
      border-radius 8px
    &:active
      background #eeeeee
  a
    color $light_gray_1000

</style>
