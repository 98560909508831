<template>
  <div class="fairy-tale-container">
    <div class="left">
      <div class="left-content-window">
        <div class="left-content-wrapper">
          <div class="left-content-container animate">
            <div
              v-for="(item, index) in stories"
              :key="index"
              class="left-content animate"
            >
              <span
                v-if="item.term"
                class="header"
              >{{ item.term }}:</span>
              <span class="title">{{ item.title }}</span>
              <span class="content">{{ item.content }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <span
          class="arrow-left"
          @click="allPrev()"
        ><arrow-left /></span>
        <span class="index">
          {{ ('0' + (leftCurrentIndex % stories.length + 1)).slice(-2) }} / {{ ('0' + stories.length).slice(-2) }}
        </span>
        <span
          class="arrow-right"
          @click="allNext()"
        ><arrow-left /></span>
      </div>
    </div>
    <div class="middle-window">
      <div class="middle-wrapper">
        <div class="middle-container animate">
          <div
            v-for="(item, index) in stories"
            :key="index"
            class="middle animate"
          >
            <div class="video-wrapper">
              <video
                v-show="clickedVideo"
                class="video-tag"
                controls
                :muted="false"
                :autoplay="false"
                :loop="false"
                preload="none"
              >
                <source
                  :src="item.videoSrc"
                  type="video/mp4"
                >
              </video>
              <div
                v-if="!clickedVideo"
                class="video-mask"
                :style="{backgroundImage: 'url(' + item.src + ')'}"
              />
            </div>
          </div>
        </div>
      </div>
      <i
        v-if="!clickedVideo"
        class="play-button"
        @click="playVideo"
      />
    </div>
    <div class="right-window">
      <div class="right-wrapper">
        <div class="right-container animate">
          <div
            v-for="(item, index) in stories"
            :key="index"
            class="right animate"
          >
            <span
              v-if="item.term"
              class="header"
            >{{ item.term }}</span>
            <span class="title">{{ item.title }}</span>
            <div
              class="sub-video"
              :style="{backgroundImage: 'url(' + item.src + ')'}"
              @click="playSubVideo"
            />
          </div>
        </div>
      </div>
      <i
        class="sub-play-button"
        @click="playSubVideo"
      />
    </div>
  </div>
</template>

<script>
  import ArrowLeft from './ArrowLeft.vue'

  export default {
    name: 'FairyTale',
    components: {
      ArrowLeft,
    },
    props: {
      stories: {
        type: Array,
        default: () => ([]),
      },
    },
    data() {
      return {
        currentIndex: 0,
        leftCurrentIndex: 0,
        middleCurrentIndex: 0,
        rightCurrentIndex: 0,
        playerItem: {},
        currentStories: [],
        fairyAmount: 0,
        translationComplete: true,
        currTransl: [],
        moveOffset: 0,
        clickedVideo: false,
        leftTranslationComplete: true,
        middleTranslationComplete: true,
        rightTranslationComplete: true,
        leftMoveOffset: 0,
        middleMoveOffset: 0,
        rightMoveOffset: 0,
        leftCurrTransl: [],
        middleCurrTransl: [],
        rightCurrTransl: [],
        storiesSum: 0,
      }
    },
    computed: {
    },
    mounted() {
      this.container = document.querySelector('.fairy-tale-container')
      this.leftCarouselDisplay()
      this.middleCarouselDisplay()
      this.rightCarouselDisplay()
    },
    methods: {
      leftCarouselDisplay() {
        const transitionCompleted = () => {
          this.leftTranslationComplete = true
        }
        const carousel = this.container.querySelector('.left-content-container')
        this.storiesSum = this.container.getElementsByClassName('left-content').length
        this.leftMoveOffset = Math.round(parseFloat(window.getComputedStyle(this.container.querySelector('.left-content-window')).width))
        this.container.querySelector('.left-content-container').style.width = `${this.storiesSum * this.leftMoveOffset}px`

        for (let i = 0; i < this.storiesSum; i++) {
          this.leftCurrTransl[i] = -this.leftMoveOffset
          this.container.getElementsByClassName('left-content')[i].addEventListener('transitionend', transitionCompleted, true)
          this.container.getElementsByClassName('left-content')[i].addEventListener('webkitTransitionEnd', transitionCompleted, true)
          this.container.getElementsByClassName('left-content')[i].addEventListener('oTransitionEnd', transitionCompleted, true)
          this.container.getElementsByClassName('left-content')[i].addEventListener('MSTransitionEnd', transitionCompleted, true)
        }

        carousel.insertBefore(this.container.querySelector('.left-content-container').children[this.storiesSum - 1], this.container.querySelector('.left-content-container').children[0])
      },
      middleCarouselDisplay() {
        const transitionCompleted = () => {
          this.middleTranslationComplete = true
        }
        const carousel = this.container.querySelector('.middle-container')
        this.storiesSum = this.container.getElementsByClassName('middle').length
        this.middleMoveOffset = Math.round(parseFloat(window.getComputedStyle(this.container.querySelector('.middle-window')).width))
        this.container.querySelector('.middle-container').style.width = `${this.storiesSum * this.middleMoveOffset}px`

        for (let i = 0; i < this.storiesSum; i++) {
          this.middleCurrTransl[i] = -this.middleMoveOffset
          this.container.getElementsByClassName('middle')[i].addEventListener('transitionend', transitionCompleted, true)
          this.container.getElementsByClassName('middle')[i].addEventListener('webkitTransitionEnd', transitionCompleted, true)
          this.container.getElementsByClassName('middle')[i].addEventListener('oTransitionEnd', transitionCompleted, true)
          this.container.getElementsByClassName('middle')[i].addEventListener('MSTransitionEnd', transitionCompleted, true)
        }

        carousel.insertBefore(this.container.querySelector('.middle-container').children[this.storiesSum - 1], this.container.querySelector('.middle-container').children[0])
      },
      rightCarouselDisplay() {
        const transitionCompleted = () => {
          this.rightTranslationComplete = true
        }
        const carousel = this.container.querySelector('.right-container')
        this.storiesSum = this.container.getElementsByClassName('right').length
        this.rightMoveOffset = Math.round(parseFloat(window.getComputedStyle(this.container.querySelector('.right-window')).width))
        this.container.querySelector('.right-container').style.width = `${this.storiesSum * this.rightMoveOffset}px`

        for (let i = 0; i < this.storiesSum; i++) {
          this.rightCurrTransl[i] = -(2 * this.rightMoveOffset)
          this.container.getElementsByClassName('right')[i].addEventListener('transitionend', transitionCompleted, true)
          this.container.getElementsByClassName('right')[i].addEventListener('webkitTransitionEnd', transitionCompleted, true)
          this.container.getElementsByClassName('right')[i].addEventListener('oTransitionEnd', transitionCompleted, true)
          this.container.getElementsByClassName('right')[i].addEventListener('MSTransitionEnd', transitionCompleted, true)
        }

        carousel.insertBefore(this.container.querySelector('.right-container').children[this.storiesSum - 1], this.container.querySelector('.right-container').children[0])
      },
      allPrev() {
        this.pauseVideo()

        if (this.leftTranslationComplete === true) {
          this.leftTranslationComplete = false
          this.leftCurrentIndex -= 1

          if (this.leftCurrentIndex === -1) {
            this.leftCurrentIndex = this.storiesSum - 1
          }

          const outerIndex = (this.leftCurrentIndex) % this.storiesSum

          for (let i = 0; i < this.storiesSum; i++) {
            const slide = this.container.getElementsByClassName('left-content')[i]
            slide.style.opacity = '1'
            slide.style.transform = `translateX(${this.leftCurrTransl[i] + this.leftMoveOffset}px)`
            this.leftCurrTransl[i] = this.leftCurrTransl[i] + this.leftMoveOffset
          }

          const outerSlide = this.container.getElementsByClassName('left-content')[outerIndex]
          outerSlide.style.transform = `translateX(${this.leftCurrTransl[outerIndex] - (this.leftMoveOffset * this.storiesSum)}px)`
          outerSlide.style.opacity = '0'
          this.leftCurrTransl[outerIndex] = this.leftCurrTransl[outerIndex] - (this.leftMoveOffset * this.storiesSum)
        }

        if (this.middleTranslationComplete === true) {
          this.middleTranslationComplete = false
          this.middleCurrentIndex -= 1

          if (this.middleCurrentIndex === -1) {
            this.middleCurrentIndex = this.storiesSum - 1
          }

          const outerIndex = (this.middleCurrentIndex) % this.storiesSum

          for (let i = 0; i < this.storiesSum; i++) {
            const slide = this.container.getElementsByClassName('middle')[i]
            slide.style.opacity = '1'
            slide.style.transform = `translateX(${this.middleCurrTransl[i] + this.middleMoveOffset}px)`
            this.middleCurrTransl[i] = this.middleCurrTransl[i] + this.middleMoveOffset
          }

          const outerSlide = this.container.getElementsByClassName('middle')[outerIndex]
          outerSlide.style.transform = `translateX(${this.middleCurrTransl[outerIndex] - (this.middleMoveOffset * this.storiesSum)}px)`
          outerSlide.style.opacity = '0'
          this.middleCurrTransl[outerIndex] = this.middleCurrTransl[outerIndex] - (this.middleMoveOffset * this.storiesSum)
        }

        if (this.rightTranslationComplete === true) {
          this.rightTranslationComplete = false
          this.rightCurrentIndex -= 1

          if (this.rightCurrentIndex === -1) {
            this.rightCurrentIndex = this.storiesSum - 1
          }

          const outerIndex = (this.rightCurrentIndex) % this.storiesSum

          for (let i = 0; i < this.storiesSum; i++) {
            const slide = this.container.getElementsByClassName('right')[i]
            slide.style.opacity = '1'
            slide.style.transform = `translateX(${this.rightCurrTransl[i] + this.rightMoveOffset}px)`
            this.rightCurrTransl[i] = this.rightCurrTransl[i] + this.rightMoveOffset
          }

          const outerSlide = this.container.getElementsByClassName('right')[outerIndex]
          outerSlide.style.transform = `translateX(${this.rightCurrTransl[outerIndex] - (this.rightMoveOffset * this.storiesSum)}px)`
          outerSlide.style.opacity = '0'
          this.rightCurrTransl[outerIndex] = this.rightCurrTransl[outerIndex] - (this.rightMoveOffset * this.storiesSum)
        }
      },
      allNext() {
        this.pauseVideo()

        if (this.leftTranslationComplete === true) {
          this.leftTranslationComplete = false
          const outerIndex = this.leftCurrentIndex % this.storiesSum
          this.leftCurrentIndex += 1

          for (let i = 0; i < this.storiesSum; i++) {
            const slide = this.container.getElementsByClassName('left-content')[i]
            slide.style.opacity = '1'
            slide.style.transform = `translateX(${this.leftCurrTransl[i] - this.leftMoveOffset}px)`
            this.leftCurrTransl[i] = this.leftCurrTransl[i] - this.leftMoveOffset
          }

          const outerSlide = this.container.getElementsByClassName('left-content')[outerIndex]
          outerSlide.style.transform = `translateX(${this.leftCurrTransl[outerIndex] + (this.leftMoveOffset * this.storiesSum)}px)`
          outerSlide.style.opacity = '0'
          this.leftCurrTransl[outerIndex] = this.leftCurrTransl[outerIndex] + (this.leftMoveOffset * this.storiesSum)
        }

        if (this.middleTranslationComplete === true) {
          this.middleTranslationComplete = false
          const outerIndex = this.middleCurrentIndex % this.storiesSum
          this.middleCurrentIndex += 1

          for (let i = 0; i < this.storiesSum; i++) {
            const slide = this.container.getElementsByClassName('middle')[i]
            slide.style.opacity = '1'
            slide.style.transform = `translateX(${this.middleCurrTransl[i] - this.middleMoveOffset}px)`
            this.middleCurrTransl[i] = this.middleCurrTransl[i] - this.middleMoveOffset
          }

          const outerSlide = this.container.getElementsByClassName('middle')[outerIndex]
          outerSlide.style.transform = `translateX(${this.middleCurrTransl[outerIndex] + (this.middleMoveOffset * this.storiesSum)}px)`
          outerSlide.style.opacity = '0'
          this.middleCurrTransl[outerIndex] = this.middleCurrTransl[outerIndex] + (this.middleMoveOffset * this.storiesSum)
        }

        if (this.rightTranslationComplete === true) {
          this.rightTranslationComplete = false
          const outerIndex = this.rightCurrentIndex % this.storiesSum
          this.rightCurrentIndex += 1

          for (let i = 0; i < this.storiesSum; i++) {
            const slide = this.container.getElementsByClassName('right')[i]
            slide.style.opacity = '1'
            slide.style.transform = `translateX(${this.rightCurrTransl[i] - this.rightMoveOffset}px)`
            this.rightCurrTransl[i] = this.rightCurrTransl[i] - this.rightMoveOffset
          }

          const outerSlide = this.container.getElementsByClassName('right')[outerIndex]
          outerSlide.style.transform = `translateX(${this.rightCurrTransl[outerIndex] + (this.rightMoveOffset * this.storiesSum)}px)`
          outerSlide.style.opacity = '0'
          this.rightCurrTransl[outerIndex] = this.rightCurrTransl[outerIndex] + (this.rightMoveOffset * this.storiesSum)
        }
      },
      playVideo() {
        this.playerItem = this.container.getElementsByClassName('video-tag')[(this.middleCurrentIndex + 1) % this.storiesSum]
        this.playerItem.play()
        this.clickedVideo = true
      },
      pauseVideo() {
        this.playerItem = this.container.getElementsByClassName('video-tag')[(this.middleCurrentIndex + 1) % this.storiesSum]
        this.playerItem.pause()
        this.clickedVideo = false
      },
      playSubVideo() {
        this.allNext()
        this.playVideo()
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .left-content-window
    width 264px
    height 290px
    position relative
    padding 0
    margin 0
    .left-content-wrapper
      overflow hidden
      max-width 100%
      height 290px
      margin 0
      .left-content-container
        position relative
        width 5000px
        height 290px
        padding 0
        margin 0
        .left-content
          float left
          position relative
          transform translateX(-264px)
  .middle-window
    box-sizing border-box
    width 464px
    height 260px
    position relative
    .middle-wrapper
      overflow hidden
      max-width 100%
      height 260px
      margin 0
      .middle-container
        position relative
        width 5000px
        height 260px
        padding 0
        margin 0
        .middle
          float left
          position relative
          transform translateX(-464px)
    .play-button
      display inline-block
      position absolute
      left 200px
      top 98px
      width 64px
      height 64px
      cursor pointer
      border-radius 50%
      overflow hidden
      background-image url("//ci.xiaohongshu.com/7e7b7932-92e7-4c41-9a53-ce76a06f8ecf")
      background-repeat no-repeat
      background-size cover
  .right-window
    width 203px
    height 300px
    position relative
    margin-left 14px
    .right-wrapper
      overflow hidden
      max-width 100%
      height 300px
      margin 0
      .right-container
        position relative
        width 5000px
        padding 0
        margin 0
        .right
          float left
          position relative
          transform translateX(-406px)
    .sub-play-button
      position absolute
      left 88px
      bottom 53px
      width 26px
      height 26px
      border-radius 50%
      cursor pointer
      background-image url("//ci.xiaohongshu.com/7e7b7932-92e7-4c41-9a53-ce76a06f8ecf")
      background-repeat no-repeat
      background-size cover
      overflow hidden
  .animate
    -webkit-transition-duration 0.5s
    -moz-transition-duration 0.5s
    -o-transition-duration 0.5s
    transition-duration 0.5s
    -webkit-transition-property -webkit-transform
    -moz-transition-property -moz-transform
    -o-transition-property -o-transform
    transition-property transform
    -webkit-transition-timing-function ease-out
    -moz-transition-timing-function ease-out
    -o-transition-timing-function ease-out
    transition-timing-function ease-out

  .fairy-tale-container
    width 1013px
    display flex
    flex-direction row
    justify-content center
    align-items flex-end
    .left
      width 332px
      height 415px
      padding 40px 34px
      background-image url("//ci.xiaohongshu.com/3bc5db57-f38a-4aad-a297-01a29e314993")
      background-repeat no-repeat
      background-size cover
      display flex
      flex-direction column
      justify-content flex-start
      align-items flex-start
      color #fff
      .left-content
        width 264px
        height 290px
        overflow hidden
        display flex
        flex-direction column
        justify-content flex-start
        align-items flex-start
        .header
          font-size 14px
          line-height 20px
        .title
          width 264px
          margin-top 15px
          margin-bottom 35px
          font-size 26px
          line-height 39px
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
        .content
          width 264px
          font-size 14px
          line-height 21px
          display -webkit-box
          -webkit-box-orient vertical
          -webkit-line-clamp 6
          overflow hidden
      .footer
        width 172px
        font-size 14px
        line-height 16px
        display flex
        flex-direction row
        justify-content space-between
        align-items center
        .arrow-left
          width 16px
          height 16px
          cursor pointer
          margin-right 44px
        .arrow-right
          width 16px
          height 16px
          cursor pointer
          margin-left 44px
          transform rotate(180deg)
    .middle
      width 464px
      height 260px
      display flex
      flex-direction column
      justify-content center
      align-items center
      .video-wrapper
        position relative
        width 464px
        height 260px
        overflow hidden
        .video-tag
          width 464px
          min-height 100%
          height auto
          top 50%
          left 50%
          transform translate(-50%, -50%)
          position absolute
        .video-mask
          width 100%
          height 100%
          position absolute
          top 0
          left 0
          background-repeat no-repeat
          background-size cover
    .right
      width 203px
      height 300px
      display flex
      flex-direction column
      justify-content flex-end
      align-items flex-start
      .header
        font-size 14px
        line-height 16px
        color #333
        margin-bottom 20px
        font-weight 500
      .title
        font-size 14px
        line-height 20px
        font-weight 500
        color #333
        margin-bottom 14px
      .sub-video
        width 203px
        height 132px
        background-repeat no-repeat
        background-size cover
        cursor pointer
</style>
