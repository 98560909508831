<template>
  <div class="process-card-container">
    <div class="inner-enter">
      <div class="inner-flow">
        <div
          v-for="(item,index) in processCards"
          :key="index"
          class="list"
        >
          <div class="title-icon">SETP 0{{ index + 1 }}</div>
          <p class="title">{{ item.title }}</p>
          <div class="content">
            <p class="pra-one">{{ item.topContent }}</p>
            <div>
              <p
                v-if="!item.prefix && !item.suffix && !item.link"
                class="pra-two"
              >{{ item.bottomContent }}</p>
              <span
                v-if="item.prefix"
                class="prefix-link"
              >{{ item.prefix }}</span>
              <a
                v-if="item.link"
                class="pra-two link"
                :href="`${item.link}`"
                target="_blank"
              >{{ item.bottomContent }}</a>
              <span
                v-if="item.suffix"
                class="suffix-link"
              >{{ item.suffix }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ProcessCards',
    props: {
      processCards: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .inner-enter
    .inner-flow
      position relative
      width 1080px
      margin 0 auto
      display flex
      flex-direction row
      justify-content center
      align-items flex-start
      .list
        width 320px
        height 240px
        text-align center
        background #fff
        color #2c2c2c
        font-size 20px
        font-weight 500
        margin 0 20px
        border-radius 8px
        box-shadow 0px 2px 20px 0px rgba(0,0,0,0.03)
        .title-icon
          width 100px
          height 37px
          margin-top 15px
          color white
          display flex
          align-items center
          background: linear-gradient(90deg, #FF5267 0%, #FF7D8D 100%);
          border-radius: 0px 100px 100px 0px;
          padding 5px 15px 5px 10px
          font-size 18px
        .title
          margin 25px 0 0 0
        .content
          font-size 16px
          line-height 1.5
          color #747474
          font-weight normal
          margin 18px 0 0 0
        .pra-one
        .pra-two
          margin 5px 0
        .link
          color #5B92E1
          font-weight 500
          cursor pointer
</style>
