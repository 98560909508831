<template>
  <div class="rating-item">
    <div class="inner">
      <div class="right-content">
        <h2 class="title">小红书评价规则</h2>
        <div
          v-for="(item,indexnum) in agreementData"
          :id="indexnum"
          :key="indexnum"
          class="content"
          :index="indexnum"
        >
          <p
            v-if="item.ifMom"
            class="test"
          >
            <span class="short-nav">{{ item.chineseNum }}、</span>
            <span>{{ item.content.main }}</span>
            <span
              v-for="(list, index) in item.content.add"
              :key="index"
              class="add"
            >
              {{ list.chineseNum }}<span>{{ list.content }}</span>
            </span>
            <span>{{ item.content.extra.content }}</span>
          </p>
          <p
            v-else
            class="test"
          >
            <span class="short-nav">{{ item.chineseNum }}、</span>
            {{ item.content }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { PERSONAL_AGREEMENT } from './meta'

  export default {
    data() {
      return {
        agreementData: PERSONAL_AGREEMENT,
        changefix: true,
      }
    },
    methods: {
      jump(index) {
        return `#${index}`
      },
      listPositive(index) {
        if (index > 10) {
          this.changefix = false
        }
      },
    },
  }
</script>
<style lang="stylus">
  .test
    em
      font-style normal
      color #333
      font-weight bold
</style>
<style lang="stylus" scoped>
  .rating-item
    background #FBFBFB
    .inner
      width 960px
      margin 50px auto 0 auto
      overflow hidden
      position relative
      .right-content
        width 710px
        float left
        margin-left 100px
        background #fff
        margin-bottom 50px
        padding 0 20px 20px 20px
        .title
          font-size 20px
          font-weight 600
          margin 30px auto 0 auto
          text-align center
          color #000
          border-bottom 1px dashed #eee
          padding-bottom 30px
        .content
          padding-top 5px
          margin 0 15px
          .test
            margin 10px 0 0 0
            line-height 28px
            .short-nav
              font-size 14px
              font-weight 600
              color #000
              margin 0
            .add
              display block
            .num
              margin-right 10px
            .add
              display block
</style>
