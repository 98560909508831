<template>
  <div class="news-list">
    <div
      v-for="item in sliceNews"
      :key="item.id"
      class="news-item"
      :class="{hoverable: item.src || item.index }"
      @click="viewReport(item)"
    >
      <span class="title">{{ item.title }}</span>
      <span class="date-press">
        <span
          v-if="item.press"
          class="press"
        >{{ item.press }}</span>
        <span class="date">{{ item.date }}</span>
      </span>
    </div>
    <div class="more-item">
      <span
        class="more"
        @click="forMore"
      ><span>{{ more }}</span><arrow-right class="right-icon" /></span>
    </div>
  </div>
</template>

<script>
  import ArrowRight from './ArrowRight.vue'

  export default {
    name: 'CommonNews',
    components: {
      ArrowRight,
    },
    props: {
      news: {
        type: Array,
        default: () => ([]),
      },
      limit: {
        type: Number,
        default: 5,
      },
    },
    data() {
      return {
      }
    },
    computed: {
      sliceNews() {
        let array = []

        this.news.forEach(item => {
          array = array.concat(item.activities)
        })

        return array.slice(0, this.limit)
      },
      more() {
        return '查看更多'
      },
    },
    methods: {
      forMore() {
        this.$emit('forMore')
      },
      viewReport(news) {
        if (news.src) {
          window.open(news.src, '_blank')
        }

        if (news.index) {
          const { href } = this.$router.resolve({
            name: 'ActivityItem',
            query: {
              id: news.index,
              language: 'zh-CN',
            },
          })

          window.open(href, '_self')
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .news-list
    width 940px
    .news-item
      font-size 14px
      line-height 16px
      color #333
      margin 0 0 40px 0
      display flex
      flex-direction row
      justify-content space-between
      align-items center
      &.hoverable
        cursor pointer
        &:hover
          color #FF2442
      .date-press
        display flex
        flex-direction row
        justify-content flex-end
        align-items center
        .press
          margin-right 50px
          text-align right
        .date
          width 75px
          font-family Monospaced
          text-align right
    .more-item
      margin 0
      display flex
      flex-direction row
      justify-content flex-end
      align-items center
      .more
        color #FF2442
        font-size 16px
        line-height 22px
        cursor pointer
        .right-icon
          margin-left 16px
</style>
