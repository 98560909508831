<template>
  <div>
    <NewBanner
      class="banner"
      :img-src="imgSrc"
      :show-text="newsCenter.showText"
      :inner-style="{ backgroundColor: 'rgba(51, 51, 51, 0.3)' }"
    />
    <div class="content">
      <div
        v-if="newsCenter.newsTitle"
        class="report"
      >
        <span class="title">{{ newsCenter.newsTitle }}</span>
        <common-news
          :news="newsCenter.news"
          :limit="5"
          @forMore="forMore('Reports')"
        />
      </div>
      <div class="activity">
        <span class="title">{{ newsCenter.activitiesTitle }}</span>
        <common-news
          :news="newsCenter.activities"
          :limit="5"
          @forMore="forMore('Activities')"
        />
      </div>
      <div class="trophy">
        <span class="title">{{ newsCenter.trophyTitle }}</span>
        <common-news
          :news="newsCenter.trophys"
          :limit="5"
          @forMore="forMore('Trophy')"
        />
      </div>
      <div class="fairyTale">
        <span class="title">{{ newsCenter.storiesTitle }}</span>
        <div class="musicLife">
          <fairy-tale :stories="newsCenter.stories" />
        </div>
      </div>
    </div>
  </div>
  <router-view />
</template>

<script>
  import NewBanner from './components/NewBanner.vue'
  import CommonNews from './components/CommonNews.vue'
  import FairyTale from './components/FairyTale.vue'

  import {
    activities,
    trophys,
    news,
    stories,
  } from './meta'

  export default {
    name: 'Main',
    components: {
      NewBanner,
      FairyTale,
      CommonNews,
    },
    data() {
      return {
        imgSrc: '//ci.xiaohongshu.com/44c37c44-266f-4cff-a9fb-ad35d41cd9d3',
      }
    },
    computed: {
      newsCenter() {
        const lang = 'zh-CN'

        return {
          activities: activities[lang],
          news: news[lang],
          trophys: trophys[lang],
          stories: stories[lang],
          more: '查看更多',
          showText: {
            title: '新闻中心',
          },
          activitiesTitle: '近期动态',
          newsTitle: '热门报道',
          trophyTitle: '荣誉奖项',
          storiesTitle: '用户故事',
          subRoute: {
            activities: {
              showText: {
                title: '近期动态',
              },
            },
            report: {
              showText: {
                title: '热门报道',
              },
            },
            trophy: {
              showText: {
                title: '公司荣誉',
              },
            },
          },
        }
      },
    },
    methods: {
      forMore(content) {
        this.$router.push({ name: content })
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .content
    background-color #fff
    padding-top 100px
  .activity
    display flex
    flex-direction column
    justify-content center
    align-items center
  .trophy
    display flex
    margin-top 100px
    flex-direction column
    justify-content center
    align-items center
  .report
    display flex
    margin-top 100px
    flex-direction column
    justify-content center
    align-items center
  .title
    margin-bottom 50px
    font-size 36px
    line-height 50px
    font-weight bold
    color #333
  .fairyTale
    margin-top 110px
    padding-bottom 180px
    display flex
    flex-direction column
    justify-content space-between
    align-items center
    background-color #FBFBFB
    .title
      margin-top 100px
    .musicLife
      display flex
      flex-direction row
      justify-content center
      .left
        flow-grow 1
        flex-shrink 1
        width 610px
        height 540px
        background #FFF url('//ci.xiaohongshu.com/4e3a8d55-e26a-4b6b-8342-bb56da1b24f5') no-repeat center center
        background-size contain
        .leftStories
          margin-left 40%
          margin-top 30%
      .right
        flow-grow 1
        flex-shrink 1
        width 830px
        height 540px
        background #FFF url('//ci.xiaohongshu.com/c9d4f647-1309-4aee-b84e-c1958aa0826b') no-repeat center center
        background-size contain
        display flex
        justify-content center
        align-items center
        .button
          display inline-block
          width 75px
          height 75px
          cursor pointer

</style>
