<template>
  <div class="page-terms">
    <Markdown url="https://pages.xiaohongshu.com/data/user_agreement" />
  </div>
</template>
<script>
  import Markdown from '~/components/Markdown.vue'

  export default {
    name: 'Media',
    components: {
      Markdown,
    },
  }
</script>
<style lang="stylus" scoped>
.page-terms
  padding-top 106px
  display flex
  justify-content center
  :deep(ul)
    padding-left 15px
    line-height 2
</style>
