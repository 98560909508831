<template>
  <div class="page-privacy">
    <Markdown url="https://page-source.xiaohongshu.com/data/user_privacy_policy" />
  </div>
</template>
<script>
  import Markdown from '~/components/Markdown.vue'

  export default {
    name: 'Privacy',
    components: {
      Markdown,
    },
  }
</script>
<style lang="stylus" scoped>
.page-privacy
  padding-top 106px
  display flex
  justify-content center
</style>
