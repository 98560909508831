<template>
  <red-header />
  <router-view />
  <qr-code
    v-if="!isHome && !isFeeds"
    class="fixed-qr-code"
  />
  <red-footer
    v-if="!isFeeds"
    class="footer"
    :class="{ 'home-footer': isHome }"
    :is-home="isHome"
  />
</template>

<script setup lang="ts">
  import {
    computed, onBeforeMount, onServerPrefetch, useSSRContext,
  } from 'vue'
  import { useRoute } from 'vue-router'
  import RedHeader from './Header/Index.vue'
  import RedFooter from './Footer.vue'
  import QrCode from '~/components/QrCode.vue'
  import { useUserStore } from '~/store/user'
  import { useGlobalStore } from '~/store/global'

  const userStore = useUserStore()
  const globalStore = useGlobalStore()
  const route = useRoute()
  const isHome = computed(() => route.name === 'Home' || route.path.startsWith('/login'))
  const isFeeds = computed(() => route.path.startsWith('/explore'))

  onServerPrefetch(async () => {
    const ctx = useSSRContext()
    const accept = ctx?.headers.accept
    globalStore.supportWebp = accept.includes('webp')
    await userStore.getUserInfo()
  })

  if (process.env.BROWSER) {
    globalStore.detectWebp()
  }

  // 如果未登录，调激活接口
  onBeforeMount(async () => {
    if (!userStore.loggedIn) {
      await userStore.getUserInfo()
    }
  })

</script>
<style lang="stylus" scoped>
  .footer
    padding-bottom 30px
    &.home-footer
      position fixed
      bottom 0
      left 50%
      transform translateX(-50%)
  @media screen and (min-width: 1305px) and (max-width: 1400px)
    .footer
      zoom 0.9
  @media screen and (min-width: 1250px) and (max-width: 1305px)
    .header
      zoom 0.9
    .footer
      zoom 0.9
  @media screen and (max-width: 1250px) and (min-width: 1150px)
    .header
      zoom 0.9
    .footer
      zoom 0.8
  @media screen and (max-width: 1150px) and (min-width: 1101px)
    .header
      zoom 0.8
    .footer
      zoom 0.8
  @media screen and (max-width: 1100px) and (min-width: 1046px)
    .header
      zoom 0.8
    .footer
      zoom 0.7
  @media screen and (max-width: 1046px)
    .header
      zoom 0.7
    .footer
      zoom 0.6
</style>
