<template>
  <div>
    <div class="inner-brief">
      <div class="content">
        <div class="red-background" />
        <div class="all-list">
          <div
            v-for="(item,index) in brandCards"
            :key="index"
            class="each-card"
          >
            <img
              class="pic"
              :src="item.imgSrc"
            >
            <h6 class="title">{{ item.title }}</h6>
            <p class="pra-one">{{ item.topContent }}</p>
            <p class="pra-two">{{ item.bottomContent }}</p>
          </div>
        </div>
        <div class="bottom-back" />
        <div class="red-shadow" />
        <div class="button-group">
          <a
            href="//ad.xiaohongshu.com/"
            class="btn"
            target="_blank"
          >立即申请</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BriefCards',
    props: {
      brandCards: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .inner-brief
    .content
      width 1080px
      margin 0 auto
      position relative
      height 432px
      .red-background
        background linear-gradient(to right, #FF5267, #FF7D8D)
        height 365px
        width 980px
        border-radius 8px
        margin-left 50px
        z-index 3
        position absolute
        top 0
        left 0
      .all-list
        overflow hidden
        position absolute
        width 100%
        margin-top 50px
        z-index 4
        display flex
        flex-direction row
        justify-content center
        align-items flex-start
        .each-card
          background #fff
          width 192px
          height 233px
          border-radius 4px
          margin 0 19px
          display flex
          flex-direction column
          justify-content flex-start
          align-items center
          padding 30px 15px 0
          &:first-child,&:last-child
            box-shadow -2px 0 30px 0 rgba(255,39,65,0.1)
          .pic
            width 60px
            height 60px
            border-radius 50%
            overflow hidden
            margin 0
            margin-bottom 15px
          .title
            font-size 18px
            margin 0
            margin-bottom 10px
            color #555
          .pra-one,.pra-two
            text-align justify
            font-size 15px
            color #999
            margin 0 0 5px 0
      .red-shadow
        width 900px
        height 50px
        opacity 1
        box-shadow 0 3px 60px 0 rgba(255,39,65,0.4)
        position absolute
        bottom 67px
        left 50%
        margin-left -450px
        z-index 2
      .bottom-back
        background url('//ci.xiaohongshu.com/1b3a0f42-da7d-481e-8d4f-51cfa8943373') no-repeat
        background-size contain
        width 834px
        height 84px
        display block
        z-index 1
        position absolute
        bottom 1px
        left 114px
      .button-group
        position absolute
        width 100%
        bottom 44px
        height 50px
        display flex
        flex-direction row
        justify-content center
        align-items flex-start
        .btn
          width 240px
          height 50px
          border-radius 25px
          box-shadow 0 0 25px 0 rgba(255,39,65,0.1)
          line-height 50px
          text-align center
          background #fff
          color #FF2741
          font-size 22px
          font-weight 500
          z-index 5
          margin-left 20px
          &:first-child
            margin-left 0
</style>
